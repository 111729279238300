import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, NavLink as RouterNavLink, Redirect, useRouteMatch, useLocation, RouteComponentProps } from 'react-router-dom';

import { Card, CardHeader, CardBody, CardTitle, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, FormGroup, Button } from 'reactstrap';

import { PendingSection } from './pending-section/PendingSection';
import { SentSection } from './sent-section/SentSection';
import { FailedSection } from './failed-section/FailedSection';

interface Props extends RouteComponentProps {}

export const Notifications = (props: Props) => {
	const { path, url } = useRouteMatch();
	const [activeTab, setActiveTab] = useState('pending');
	const { pathname } = useLocation();

	useEffect(() => {
		if (pathname === `${url}/pending`) {
			setActiveTab('pending');
		} else if (pathname === `${url}/sent`) {
			setActiveTab('sent');
		}
	}, [pathname]);

	/**
	 * The render function for the React component
	 */
	return (
		<div className="content">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4">Notifications</CardTitle>
						</CardHeader>
						<CardBody>
							<div className="nav-tabs-navigation">
								<div className="nav-tabs-wrapper">
									<Nav id="tabs" role="tablist" tabs>
										<NavItem key={`pending-nav-item`}>
											<NavLink
												tag={RouterNavLink}
												to={`${url}/pending`}
												exact
												className={activeTab === 'pending' ? 'active' : ''}
												onClick={(e) => setActiveTab('pending')}
											>
												Pending
											</NavLink>
										</NavItem>
										<NavItem key={`sent-nav-item`}>
											<NavLink
												tag={RouterNavLink}
												to={`${url}/sent`}
												exact
												className={activeTab === 'sent' ? 'active' : ''}
												onClick={(e) => setActiveTab('sent')}
											>
												Sent
											</NavLink>
										</NavItem>
										<NavItem key={`failed-nav-item`}>
											<NavLink
												tag={RouterNavLink}
												to={`${url}/failed`}
												exact
												className={activeTab === 'failed' ? 'active' : ''}
												onClick={(e) => setActiveTab('failed')}
											>
												Failed
											</NavLink>
										</NavItem>
									</Nav>
								</div>
							</div>
							<TabContent>
								<TabPane>
									<Switch>
										<Route path={`${path}/pending`}>
											<PendingSection {...props} />
										</Route>
										<Route path={`${path}/sent/:notificationID?`}>
											<SentSection {...props} />
										</Route>
										<Route exact path={path}>
											<Redirect push to={`${path}/pending`} />
										</Route>
										<Route path={`${path}/failed/:notificationID?`}>
											<FailedSection {...props} />
										</Route>
									</Switch>
								</TabPane>
							</TabContent>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
