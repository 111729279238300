import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { RoleDetails } from './partials/RoleDetails';
import { RolesTable } from './partials/RolesTable';

interface Props extends RouteComponentProps {}

export const AdministratorRoles = (props: Props) => {
	const { location, match } = props;

	return (
		<div className="content administrators-roles-page">
			<Switch location={location}>
				<Route exact path={match.path + '/add'} render={(props) => <RoleDetails {...props} />} />
				<Route exact path={match.path + '/:uid'} render={(props) => <RoleDetails {...props} />} />
				<Route exact path={match.path} render={(props) => <RolesTable {...props} />} />
			</Switch>
		</div>
	);
};
