import React, { useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/browser';

import { Button, Alert } from 'reactstrap';

import UserContext from '../../../UserContext';

import { frontend } from '../../../../api';

import { DocumentUpload as UploadDocument } from '../../../DocumentUpload';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {
	clearancesRootPath: string;
}

export const DocumentUpload = (props: Props) => {
	const { user } = useContext(UserContext);
	const { clearancesRootPath, history } = props;
	const [clearanceSettings, setClearanceSettings] = useState<
		Array<{ docID: string; name: string; certification_date: string; approved?: boolean | undefined; readonly?: boolean | undefined }>
	>([]);
	const [uploadResultAlert, setUploadResultAlert] = useState({ show: false, status: 'danger', message: 'Failed to upload' });

	useEffect(() => {
		frontend
			.getClearanceSettings(user!.token!)
			.then((clearanceSettings) => {
				setClearanceSettings(clearanceSettings);
			})
			.catch((e) => {
				// TODO: Handle better
				console.log('error');
				console.log(e);
			});
	}, []);

	const handleCancelUploadClick = () => {
		history.push(clearancesRootPath);
	};

	const handleConfirmUploadStepClick = async (formData: FormData) => {
		try {
			const data = await frontend.uploadClearance(user != null && user.token != null ? user.token : '', formData);
			if (data != null) {
				setUploadResultAlert({ show: true, status: 'success', message: 'Successfully uploaded file.' });
			}
		} catch (err: any) {
			console.log('ERR');
			console.log(err);
			console.log(err.message);
			setUploadResultAlert({ show: true, status: 'danger', message: err.message != null ? err.message : uploadResultAlert.message });
			Sentry.captureException(err);
		}
	};

	const handleUploadResultAlertClick = () => {
		setUploadResultAlert({ show: false, status: 'danger', message: 'Failed to upload' });
	};

	return (
		<div className="clearances-tab-document-upload">
			<Alert color={uploadResultAlert.status} isOpen={uploadResultAlert.show} toggle={handleUploadResultAlertClick} fade={true}>
				<span>{uploadResultAlert.message}</span>
			</Alert>

			<UploadDocument
				key={'clearances-tab-document-upload-upload-document'}
				clearanceSettings={clearanceSettings}
				validFileTypes={'image/*'}
				handleUploadFiles={handleConfirmUploadStepClick}
			/>
			<div className="back-button">
				<Button color="link" size="sm" onClick={() => handleCancelUploadClick()}>
					Back
				</Button>
			</div>
		</div>
	);
};
