import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { MembersImport } from './MembersImport';
import { MembersDisplay } from './MembersDisplay';

interface Props extends RouteComponentProps {}

export const Members = (props: Props) => {
	const { location, match } = props;

	return (
		<div className="content members">
			<Switch location={location}>
				<Route path={match.path + '/import'} component={MembersImport} />
				<Route path={match.path} component={MembersDisplay} />
			</Switch>
		</div>
	);
};
