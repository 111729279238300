import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';

import { Button, UncontrolledTooltip, Alert, Table } from 'reactstrap';

import { RecipientsSectionAddModify } from './RecipientsSectionAddModify';
import { SettingsClearancesType } from 'api/settings/clearances';
import { SettingsDataField } from 'api/settings/general';
import { SettingsNotificationsListEntryRecipient, SettingsNotificationsListEntryRecipientCondition } from 'api/settings/notifications';

interface Props {
	recipientsData: Array<SettingsNotificationsListEntryRecipient>;
	dynamicDataFields: Array<SettingsDataField>;
	clearanceFields: Array<SettingsClearancesType>;
	handleInputFieldChange: (newValue: any) => void;
}

export const RecipientsSection = (props: Props) => {
	const { recipientsData, dynamicDataFields, clearanceFields, handleInputFieldChange } = props;

	const [editData, setEditData] = useState<SettingsNotificationsListEntryRecipient | null>(null);

	const handleDeleteRecipientClick = (index: number) => {
		let newRecipientsData = [...recipientsData];
		newRecipientsData.splice(index, 1);

		handleInputFieldChange(newRecipientsData);
	};

	const handleEditRecipientClick = (index: number) => {
		setEditData(recipientsData[index]);
	};

	const handleRecipientSectionSave = (newRecord: SettingsNotificationsListEntryRecipient) => {
		let newData = [...recipientsData];

		if (editData != null) {
			const recordIndex = newData.indexOf(editData);
			if (recordIndex > -1) {
				newData[recordIndex] = newRecord;
				setEditData(null);
			}
		} else {
			if (newData == null) {
				newData = [];
			}

			newData.push(newRecord);
		}

		handleInputFieldChange(newData);
	};

	const handleRecipientSectionCancel = () => {
		if (editData != null) {
			setEditData(null);
		}
	};

	const generateConditionsString = (conditions: Array<SettingsNotificationsListEntryRecipientCondition>) => {
		if (conditions == null || conditions.length === 0) {
			return '';
		}

		let outputStr = '';
		let index = 0;
		for (const condition of conditions) {
			let fieldTypeValue = 'value';
			let fieldValue = '';
			if (condition.field_type === 'datafield' && condition.field === 'document-expiration') {
				condition.field_type = 'value';
			}

			switch (condition.field_type) {
				case 'value':
					fieldTypeValue = 'value';
					fieldValue = 'Document Expiration';
					break;
				case 'datafield':
					fieldTypeValue = 'field';
					fieldValue = dynamicDataFields.filter((field) => field.uid === condition.field)[0].name;
					break;
				case 'clearance':
					fieldTypeValue = 'clearance';

					break;
			}

			let logicOperatorValue = '';
			switch (condition.logicOperator) {
				case 'equal-to':
					logicOperatorValue = 'equal to';
					break;
				case 'not-equal-to':
					logicOperatorValue = 'not equal to';
					break;
				case 'contains':
					logicOperatorValue = 'contains';
					break;
				case 'starts-with':
					logicOperatorValue = 'starts with';
					break;
				case 'ends-with':
					logicOperatorValue = 'ends with';
					break;
			}

			let valueTypeValue = '';
			switch (condition.value_type) {
				case 'user':
					valueTypeValue = "user's";
					break;
				case 'value':
					valueTypeValue = 'value';
					break;
			}

			let valueValue = condition.value;

			switch (condition.field_type) {
				case 'value':
					if (condition.value_type === 'user') {
						valueValue = dynamicDataFields.filter((field) => field.uid === condition.value)[0].name;
					}
					break;
				case 'datafield':
					if (condition.value_type === 'user') {
						valueValue = dynamicDataFields.filter((field) => field.uid === condition.value)[0].name;
					} else if (condition.value_type === 'value' && condition.field !== 'document-expiration') {
						const dynamicField = dynamicDataFields.filter((field) => field.uid === condition.field)[0];

						if (dynamicField.attributes.type === 'string') {
							valueValue = condition.value;
						} else if (dynamicField.attributes.type === 'int') {
							valueValue = condition.value;
						} else if (dynamicField.attributes.type === 'date') {
							valueValue = condition.value;
						} else if (dynamicField.attributes.type === 'email') {
							valueValue = condition.value;
						} else if (dynamicField.attributes.type === 'dropdown') {
							valueValue = dynamicField.attributes.options!.filter((option) => option.uid === condition.value)[0].name;
						} else if (dynamicField.attributes.type === 'group') {
							// TODO: Impelment correctly
							valueValue = condition.value;
						}
					}
					break;
				case 'clearance':
					valueValue = clearanceFields.filter((field) => field.uid === condition.value)[0].name;
					break;
			}

			outputStr += `${index > 0 ? 'and' : 'Where'} ${fieldTypeValue} ${fieldValue} is ${logicOperatorValue} the ${valueTypeValue} "${valueValue}" `;

			index++;
		}

		return outputStr;
	};

	return (
		<>
			{recipientsData != null && recipientsData.length > 0 ? (
				<Table responsive style={{ marginBottom: 0 }}>
					<thead className="text-primary">
						<tr>
							<th className="text-left">Recipient</th>
							<th className="text-center">Conditions</th>
							<th className="text-right">Actions</th>
						</tr>
					</thead>
					<tbody>
						{recipientsData.map((row, key) => {
							const { canDelete, canModify } = (row as any).attributes != null ? (row as any).attributes : { canDelete: true, canModify: true };

							let recipientTypeStr;
							switch (row.recipient_type) {
								case 'to':
									recipientTypeStr = 'To';
									break;
								case 'cc':
									recipientTypeStr = 'CC';
									break;
								default:
									recipientTypeStr = 'Recipient';
									Sentry.captureMessage(`RecipientsSection type is unknown "${row.recipient_type}"`);
									break;
							}

							let recipientStr;
							switch (row.recipient) {
								case 'user':
									recipientStr = 'User';
									break;
								case 'any-member':
									recipientStr = 'Member';
									break;
								case 'administrator':
									recipientStr = 'Administrator';
									break;
								default:
									recipientStr = 'Recipient';
									Sentry.captureMessage(`RecipientsSection recipient is unknown "${row.recipient_type}"`);
									break;
							}

							return (
								<tr key={`notification-settings-notification-section-data-field-${key}`}>
									<td className="text-center">
										{recipientTypeStr} {recipientStr}
									</td>
									<td className="text-center">{generateConditionsString(row.conditions || [])}</td>
									<td className="text-right">
										<Button
											className="btn-icon"
											color="success"
											id={`notification-settings-notification-section-data-field-${key}-edit`}
											size="sm"
											type="button"
											disabled={canModify != null && canModify !== true ? true : false}
											onClick={() => handleEditRecipientClick(key)}
										>
											<i className="fa fa-edit" />
										</Button>{' '}
										<UncontrolledTooltip delay={0} target={`notification-settings-notification-section-data-field-${key}-edit`}>
											Edit
										</UncontrolledTooltip>
										<Button
											className="btn-icon"
											color="danger"
											id={`notification-settings-notification-section-data-field-${key}-delete`}
											size="sm"
											type="button"
											disabled={canDelete != null && canDelete !== true ? true : false}
											onClick={() => handleDeleteRecipientClick(key)}
										>
											<i className="fa fa-times" />
										</Button>{' '}
										<UncontrolledTooltip delay={0} target={`notification-settings-notification-section-data-field-${key}-delete`}>
											Delete
										</UncontrolledTooltip>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			) : (
				<Alert color="secondary">
					<span>At least one recipient is required. None currently defined.</span>
				</Alert>
			)}
			<RecipientsSectionAddModify
				editData={editData!}
				dynamicDataFields={dynamicDataFields}
				clearanceFields={clearanceFields}
				onSave={handleRecipientSectionSave}
				onCancel={handleRecipientSectionCancel}
			/>
		</>
	);
};
