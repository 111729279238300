// Node modules
import React, { useState, useEffect, useCallback } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

// Types

// Exceptions
import { UnauthorizedException } from '../api/exceptions/UnauthorizedException';
import { InternalServerErrorException } from '../api/exceptions/InternalServerErrorException';
import { LoginUnsuccessfulException } from '../api/exceptions/LoginUnsuccessfulException';
import { RefreshTokenExpiredException } from '../api/exceptions/RefreshTokenExpiredException';
import { PasswordExpiredException } from '../api/exceptions/PasswordExpiredException';
import { ResourceNotFoundException } from '../api/exceptions/ResourceNotFoundException';

// Helpers
import * as sentry from '../lib/sentry';

import { ErrorHandler } from './ErrorHandler';
import { AdminPage } from './pages/AdminPage';
import { FullPage } from './pages/FullPage';
import { FullPageLoading } from '../components/FullPageLoading';

import { Login } from '../components/Auth/Login';
import { ForgotPassword } from '../components/Auth/ForgotPassword';
import { ResetPassword } from '../components/Auth/ResetPassword';

import { AuthUser, getAuthenticatedAdminUser } from '../api';
import { UserContext } from '../components/UserContext';
import { LoginOverlay } from '../components/Auth/LoginOverlay';

interface Props extends RouteComponentProps {}

export const Administrator = (props: Props) => {
	const { history } = props;

	// UserContext object state
	const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>(false);
	const [organization, setOrganization] = useState<string | null>(null);
	const [uid, setUID] = useState<string | null>(null);
	const [jwt, setJWT] = useState<string | null>(null);
	const [user, setUser] = useState<AuthUser | null>(null);

	const [userLoginOverlay, setUserLoginOverlay] = useState<{ display: boolean; callback?: () => void }>({
		display: false,
		callback: undefined,
	});

	// Administrator related state
	const [isCheckingUserAuthenticated, setIsCheckingUserAuthenticated] = useState(true);

	// https://stackoverflow.com/a/43634169/1017520
	// const originalSetItem = localStorage.setItem;

	// localStorage.setItem = function (key, value) {
	// 	console.log('HIT setItem', key, value);
	// 	const event = new Event('localStorageItemUpdate');

	// 	// event.value = value; // Optional..
	// 	// event.key = key; // Optional..

	// 	document.dispatchEvent(event);

	// 	originalSetItem.apply(this, arguments);
	// };

	/**
	 *
	 */
	function processURLParams() {
		let base64encodedUrl = '';

		// Check if we have a react-router-dom history
		if (history.location != null) {
			// Check if there is an existing query param passed in
			const search = new URLSearchParams(history.location.search);
			const locationQueryParam = search.get('l');

			if (locationQueryParam != null) {
				base64encodedUrl = locationQueryParam;
			}

			// Pull the current location
			const currentLocation = history.location.pathname;

			// Check if it isn't root or login
			if (currentLocation !== '/administrator/login' && currentLocation !== '/') {
				// const buff = window.Buffer.from(currentLocation);
				// base64encodedUrl = buff.toString('base64');
			} else {
				// This is one of them... check if there is already a base64 encoded url
				// let buff = new Buffer(data, 'base64');
				// let text = buff.toString('ascii');
			}
		}

		return base64encodedUrl;
	}

	useEffect(() => {
		function processLocalStorageUpdateEvent() {
			const localStorageString = localStorage.getItem('admin-user-authorization');

			if (localStorageString == null) {
				return null;
			}

			const { token, refresh } = JSON.parse(localStorageString);
			setJWT(token);
		}

		window.addEventListener('localStorageItemUpdate', processLocalStorageUpdateEvent);

		// TODO rehydrate from persistent storage (localStorage.getItem(myLastSavedStateKey)) -- reference https://stackoverflow.com/a/60701229/1017520
		if (
			history.location.pathname === '/administrator/login' ||
			history.location.pathname === '/administrator/forgot' ||
			history.location.pathname === '/administrator/reset'
		) {
			setIsCheckingUserAuthenticated(false);
			setIsUserAuthenticated(false);
		} else {
			getAuthenticatedAdminUser()
				.then((userObj) => {
					if (userObj != null) {
						setOrganization(userObj.organization);
						setUID(userObj.uid);
						setJWT(userObj.token!);
						setUser(userObj);
						setIsUserAuthenticated(true);
						sentry.setUser(userObj.uid);

						setIsCheckingUserAuthenticated(false);
					} else {
						const base64encodedUrl = processURLParams();
						history.push({ pathname: `/administrator/login`, search: `${base64encodedUrl !== '' ? `?l=${base64encodedUrl}` : ''}` });

						setIsCheckingUserAuthenticated(false);
						setIsUserAuthenticated(false);
					}
				})
				.catch((error) => {
					if (error instanceof UnauthorizedException) {
						const base64encodedUrl = processURLParams();
						history.push({ pathname: `/administrator/login`, search: `${base64encodedUrl !== '' ? `?l=${base64encodedUrl}` : ''}` });

						setIsCheckingUserAuthenticated(false);
						setIsUserAuthenticated(false);
					} else if (error instanceof LoginUnsuccessfulException) {
						const base64encodedUrl = processURLParams();
						history.push({ pathname: `/administrator/login`, search: `${base64encodedUrl !== '' ? `?l=${base64encodedUrl}` : ''}` });

						setIsCheckingUserAuthenticated(false);
						setIsUserAuthenticated(false);
					} else if (error instanceof RefreshTokenExpiredException) {
						const base64encodedUrl = processURLParams();
						history.push({ pathname: `/administrator/login`, search: `${base64encodedUrl !== '' ? `?l=${base64encodedUrl}` : ''}` });

						setIsCheckingUserAuthenticated(false);
						setIsUserAuthenticated(false);
					} else if (error instanceof PasswordExpiredException) {
						const base64encodedUrl = processURLParams();
						history.push({ pathname: `/administrator/login`, search: `${base64encodedUrl !== '' ? `?l=${base64encodedUrl}` : ''}` });

						setIsCheckingUserAuthenticated(false);
						setIsUserAuthenticated(false);
					} else if (error instanceof ResourceNotFoundException) {
						const base64encodedUrl = processURLParams();
						history.push({ pathname: `/administrator/login`, search: `${base64encodedUrl !== '' ? `?l=${base64encodedUrl}` : ''}` });

						setIsCheckingUserAuthenticated(false);
						setIsUserAuthenticated(false);
					} else if (error instanceof InternalServerErrorException) {
						const base64encodedUrl = processURLParams();
						history.push({ pathname: `/administrator/login`, search: `${base64encodedUrl !== '' ? `?l=${base64encodedUrl}` : ''}` });

						setIsCheckingUserAuthenticated(false);
						setIsUserAuthenticated(false);
					} else {
						console.log('Administrator caught unknown error');
						throw error;
					}
				});
		}

		return () => {
			console.log('Removing event handler');
			window.removeEventListener('localStorageItemUpdate', processLocalStorageUpdateEvent);
		};
	}, []);

	const setUserCredentials = useCallback((userObj: { access: AuthUser; refresh: string }) => {
		if (userObj != null) {
			localStorage.setItem('admin-user-authorization', JSON.stringify({ token: (userObj as any).access.token, refresh: (userObj as any).refresh }));
			setOrganization((userObj as any).access.organization);
			setUID((userObj as any).access.uid);
			setJWT((userObj as any).access.token);
			setUser({ name: (userObj as any).access.name } as any);
			setIsUserAuthenticated(true);
			sentry.setUser((userObj as any).access.uid);
		}
	}, []);

	// async function signOut() {
	// 	localStorage.removeItem('admin-user-authorization');
	// 	setUser(null);
	// 	const history = [];
	// 	history.push('/login');
	// }

	async function setDisplayUserLoginOverlay(state: any, callback?: () => void) {
		console.log('setDisplayUserLoginOverlay', state, callback != null ? true : false);
		setUserLoginOverlay({ display: state, callback });
	}

	return (
		<UserContext.Provider
			value={{
				isUserAuthenticated,
				organization: organization!,
				uid: uid!,
				jwt: jwt!,
				user: user!,

				setDisplayUserLoginOverlay: setDisplayUserLoginOverlay,
				setUserCredentials: setUserCredentials,
				signIn: () => {},
			}}
		>
			{isCheckingUserAuthenticated ? (
				<FullPageLoading />
			) : (
				<ErrorHandler>
					<Switch>
						<Route
							exact
							path="/administrator/login"
							render={(props) => (
								<FullPage {...props}>
									<Login {...props} />
								</FullPage>
							)}
						/>
						<Route
							exact
							path="/administrator/forgot"
							render={(props) => (
								<FullPage {...props}>
									<ForgotPassword {...props} />
								</FullPage>
							)}
						/>
						<Route
							exact
							path="/administrator/reset"
							render={(props) => (
								<FullPage {...props}>
									<ResetPassword {...props} />
								</FullPage>
							)}
						/>
						<Route path="/administrator*" render={(props) => <AdminPage {...props} />} />
					</Switch>
					{userLoginOverlay.display === true ? <LoginOverlay callback={userLoginOverlay.callback!} /> : <></>}
				</ErrorHandler>
			)}
		</UserContext.Provider>
	);
};
