import React, { useEffect, useContext } from 'react';

import { Button, Input, FormGroup, Label, Row, Col } from 'reactstrap';

import { setting } from '../../../../../../api';
import UserContext from '../../../../../UserContext';

interface Props {
	isValid: boolean;
	onChange: (newValue: any) => void;
	data?: { [key: string]: any };
}

export const GmailProvider = (props: Props) => {
	const { jwt } = useContext(UserContext);
	const { isValid, onChange, data } = props;

	let windowObjectReference: Window | null = null;
	let previousUrl: string | null = null;

	useEffect(() => {
		// This is the equivalent to component mount
		console.log('GmailProvider hit component mount', windowObjectReference);

		if (windowObjectReference != null) {
			windowObjectReference.close();
		}

		return () => {
			// This is the equivalent to component unmount
			console.log('GmailProvider hit component unmount', windowObjectReference);

			if (windowObjectReference != null) {
				windowObjectReference.close();
			}
		};
	}, []);

	const receiveMessage = (event: MessageEvent) => {
		// Verify that this message is coming from our popup window
		if (event.origin !== window.origin || event.source !== windowObjectReference) {
			return;
		}

		// Process the event
		const { data } = event;

		if (data != null && data.length > 0) {
			try {
				const search = data.substring(1);
				const jsonObj = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', (key, value) => {
					return key === '' ? value : decodeURIComponent(value);
				});

				// Make sure we have the expected response, code and scope
				if (jsonObj.code != null && jsonObj.scope != null) {
					// Update the data
					onChange(jsonObj);

					// Close the window
					windowObjectReference!.close();
				}
			} catch (err) {
				return;
			}
		}
	};

	const openSignInWindow = (url: string, name: string) => {
		// Remove any existing event listeners
		window.removeEventListener('message', receiveMessage);

		// Window features
		const width = 525;
		const height = 525;
		const left = window.screenX + (window.outerWidth - width) / 2;
		const top = window.screenY + (window.outerHeight - height) / 2.5;
		const strWindowFeatures = `toolbar=0, menubar=0, status=1, resizable=0, location=1, width=${width}, height=${height}, top=${top}, left=${left}`;

		if (windowObjectReference === null || windowObjectReference.closed) {
			// If the pointer to the window object in memory does not exist or if such pointer exists but the window was closed
			windowObjectReference = window.open(url, name, strWindowFeatures);
		} else if (previousUrl !== url) {
			// If the resource to load is different, then we load it in the already opened secondary window and then we bring such window back on top/in front of its parent window.
			windowObjectReference = window.open(url, name, strWindowFeatures);
			windowObjectReference!.focus();
		} else {
			// Else the window reference must exist and the window is not closed; therefore, we can bring it back on top of any other window with the focus() method.
			// There would be no need to re-create the window or to reload the referenced resource.
			windowObjectReference.focus();
		}

		// Add the listener for receiving a message from the sign in window
		window.addEventListener('message', (event) => receiveMessage(event), false);

		// Assign the previous URL
		previousUrl = url;
	};

	const handleConnectButtonClick = async () => {
		// Get the URL from API that we need to redirect to
		setting.notifications
			.getSenderGmail(jwt)
			.then(async (settings) => {
				if ((settings as any).url != null) {
					// We have a url, lets go there
					openSignInWindow((settings as any).url, 'Google Authorization');
				} else {
					throw new Error('URL not defined');
				}
			})
			.catch((e) => {
				// TODO: Handle better
				console.log('error');
				console.log(e);
			});
	};

	return (
		<>
			{data!.valid_token != null ? (
				<>
					<Row>
						<Col md={12}>
							<FormGroup>
								<Label for="emailSubject">Email Subject</Label>
								<Input
									type="text"
									name="emailSubject"
									id="emailSubject"
									placeholder="Enter Name"
									onChange={(e) => {
										//handleInputFieldChange('emailSubject', e.target.value);
									}}
									value={data!['emailSubject']}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<FormGroup>
								<Label for="emailBody">Email Body</Label>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Button
								className="pull-right"
								color="secondary"
								type="button"
								size="sm"
								onClick={() => {
									console.log('Test sender message clicked');
								}}
							>
								Send Test Message
							</Button>
						</Col>
					</Row>
				</>
			) : data!.code != null && data!.scope != null ? (
				<Button color="secondary" type="button" size="md" disabled={true}>
					Connected
				</Button>
			) : (
				<Button
					color="primary"
					type="button"
					size="md"
					disabled={!isValid}
					onClick={() => {
						handleConnectButtonClick();
					}}
				>
					Connect
				</Button>
			)}
		</>
	);
};
