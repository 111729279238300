import React, { useState } from 'react';

import { Button, Modal, Form, Input, FormGroup, Label, Row, Col, ModalHeader } from 'reactstrap';

import { GmailProvider } from './sender-record-overlay-helpers/GmailProvider';
import { FaTimes } from 'react-icons/fa';
import { SettingsNotificationsSenderEntry } from 'api/settings/notifications';

interface Props {
	show: boolean;
	onClose: () => void;
	onClosed: () => void;
	senderList: Array<{ uid: string; name: string }>;
	onSave: (inputData: SettingsNotificationsSenderEntry) => void;
	editRecord?: SettingsNotificationsSenderEntry;
}

export const SenderRecordOverlay = (props: Props) => {
	const { onSave, onClose, onClosed, show, senderList } = props;
	const [data, setData] = useState<SettingsNotificationsSenderEntry>(
		props.editRecord != null ? props.editRecord : { uid: '', name: '', provider: 'gmail', provider_options: {}, is_default: false }
	);
	const [isValid, setIsValid] = useState(false);

	const handleInputFieldChange = (fieldName: keyof SettingsNotificationsSenderEntry, newValue: any, type?: string) => {
		// Update the data
		const newData = { ...data };

		if (type === 'boolean') {
			(newData as any)[fieldName] = newValue === 'true';
		} else {
			(newData as any)[fieldName] = newValue;
		}
		setData(newData);

		// Update is the content valid
		if (newData.name != null && newData.name.length > 0) {
			setIsValid(true);
		} else {
			setIsValid(false);
		}
	};

	const handleOnSaveClick = () => {
		onSave(data);
		setData({ uid: '', name: '', provider: 'gmail', provider_options: {}, is_default: false });
	};

	return (
		<Modal className="modal-lg notification-settings-sender-section-record-overlay" isOpen={show} toggle={() => onClose()}>
			<ModalHeader toggle={() => onClose()}>{data.uid == null ? 'Add' : 'Edit'} Sender</ModalHeader>
			<div className="modal-body">
				<Form>
					<Row>
						<Col md={8}>
							<FormGroup>
								<Label for="name">Name</Label>
								<Input
									type="text"
									name="name"
									id="name"
									placeholder="Enter Name"
									onChange={(e) => {
										handleInputFieldChange('name', e.target.value);
									}}
									value={data.name}
								/>
							</FormGroup>
						</Col>
						<Col md={4}>
							<FormGroup>
								<Label for="is_default">Default</Label>
								<Input
									type="select"
									name="is_default"
									id="is_default"
									onChange={(e) => handleInputFieldChange('is_default', e.target.value, 'boolean')}
									defaultValue={data.is_default as any}
								>
									<option value={false as any}>No</option>
									<option value={true as any}>Yes</option>
								</Input>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label for="provider">Provider</Label>
								<Input
									type="select"
									name="provider"
									id="provider"
									onChange={(e) => handleInputFieldChange('provider', e.target.value)}
									defaultValue={data.provider}
								>
									<option value={null as any}>- Select Provider -</option>
									<option value={'gmail'}>Gmail</option>
									<option value={'postmark'}>Postmark</option>
								</Input>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label for="fallback_to">Fallback To</Label>
								<Input
									type="select"
									name="fallback_to"
									id="fallback_to"
									onChange={(e) => handleInputFieldChange('fallback_to', e.target.value)}
									defaultValue={data.fallback_to}
								>
									<option value={null as any}>- None -</option>
									{senderList != null && senderList.length > 0 ? (
										senderList.map((sender) => {
											return (
												<option key={sender.uid} value={sender.uid}>
													{sender.name}
												</option>
											);
										})
									) : (
										<></>
									)}
								</Input>
							</FormGroup>
						</Col>
					</Row>
					<hr />
					{data.provider === 'gmail' ? (
						<GmailProvider isValid={isValid} onChange={(e) => handleInputFieldChange('provider_options', e)} data={data.provider_options} />
					) : (
						<></>
					)}
				</Form>
			</div>
			<div className="modal-footer justify-content-center">
				<Button
					className="btn-round"
					color="primary"
					type="button"
					disabled={!isValid}
					onClick={() => {
						handleOnSaveClick();
					}}
				>
					{data.uid == null ? 'Add' : 'Save'}
				</Button>
				<Button className="btn-round" color="secondary" data-dismiss="modal" type="button" onClick={() => onClose()}>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};
