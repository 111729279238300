import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';

import { ImportTemplates } from './members-import/ImportTemplates';
import { Import } from './members-import/Import';

import { setting, member } from '../../api';
import UserContext from '../UserContext';
import { FaSpinner } from 'react-icons/fa';
import { SettingsDataField } from 'api/settings/general';
import { SettingsClearancesType } from 'api/settings/clearances';

interface Props extends RouteComponentProps {}

export const MembersImport = (props: Props) => {
	const { jwt } = useContext(UserContext);
	const { match } = props;

	const [isLoaded, setIsLoaded] = useState(false);
	const [dynamicSettingsDataFields, setDynamicSettingsDataFields] = useState<Array<SettingsDataField>>([]);
	const [clearanceFields, setClearanceFields] = useState<Array<SettingsClearancesType>>([]);

	/**
	 * This function will retrieve the notifications data from the backend
	 */
	useEffect(() => {
		Promise.resolve()
			.then(() => {
				return setting.general
					.get(jwt)
					.then((data) => {
						setDynamicSettingsDataFields(data.data_fields);
					})
					.catch((e) => {
						// TODO: Handle better
						console.log('error');
						console.log(e);
					});
			})
			.then(() => {
				return setting.clearances
					.get(jwt)
					.then((data) => {
						setClearanceFields(data.types_list);
					})
					.catch((e) => {
						// TODO: Handle better
						console.log('error');
						console.log(e);
					});
			})
			.then(() => {
				setIsLoaded(true);
			});
	}, []);

	return (
		<div className="content members-table">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4">Member Import Templates</CardTitle>
						</CardHeader>
						<CardBody>
							{isLoaded ? (
								<Switch>
									<Route
										path={`${match.path}/templates`}
										render={(props) => (
											<ImportTemplates
												{...props}
												memberImportRootPath={match.path}
												clearanceFields={clearanceFields}
												dynamicSettingsDataFields={dynamicSettingsDataFields}
											/>
										)}
									/>
									<Route
										path={`${match.path}/:importID`}
										render={(props) => (
											<Import {...props} memberImportRootPath={match.path} dynamicSettingsDataFields={dynamicSettingsDataFields} />
										)}
									/>
									<Route path={match.path}>
										<Redirect push to={`${match.path}/templates`} />
									</Route>
								</Switch>
							) : (
								<FaSpinner size={12} color="#ccc" className="fa-spin" />
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
