// Node modules
import React from 'react';
import ReactDOM from 'react-dom';
// import { createBrowserHistory } from 'history';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Types

// Exceptions

// Helpers
import * as Sentry from './lib/sentry';
import { Frontend as FrontendLayout } from './layouts/Frontend';
import { Administrator as AdministratorLayout } from './layouts/Administrator';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/paper-dashboard.scss?v=1.2.0';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// const { createBrowserHistory } = require('history');

// const hist = createBrowserHistory();

Sentry.initialize();

ReactDOM.render(
	<BrowserRouter>
		<Switch>
			<Route path="/app" render={(props) => <FrontendLayout {...props} />} />
			<Route path="/administrator" render={(props) => <AdministratorLayout {...props} />} />
		</Switch>
	</BrowserRouter>,
	document.getElementById('root')
);
