import React from 'react';

import { Button, Col, FormGroup, Label } from 'reactstrap';
import { SettingsClearancesType } from 'api/settings/clearances';
import { FileWithAttributes } from 'api/tempTypes';

interface Props {
	files: Array<FileWithAttributes>;
	onConfirm: () => void;
	clearanceSettings: Array<SettingsClearancesType>;
}

export const ConfirmUpload = (props: Props) => {
	const { files, onConfirm, clearanceSettings } = props;

	return (
		<div className="confirm-upload">
			{files.map((file, index) => {
				console.log('confirming', file.attributes.certification_date);
				const certificationDate = new Date(`${file.attributes.certification_date}T00:00:00.000Z`);

				return (
					<div key={`member-details-overlay-upload-document-confirm-upload-${index}`}>
						<h6>{file.name}</h6>
						<FormGroup row>
							<Label sm={2}>Type</Label>
							<Col sm={10}>{clearanceSettings.filter((clearance) => clearance.uid === file.attributes.type)[0].name}</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={2}>Certification Date</Label>
							<Col sm={10}>
								{(certificationDate.getUTCMonth() > 8 ? certificationDate.getUTCMonth() + 1 : '0' + (certificationDate.getUTCMonth() + 1)) +
									'/' +
									(certificationDate.getUTCDate() > 9 ? certificationDate.getUTCDate() : '0' + certificationDate.getUTCDate()) +
									'/' +
									certificationDate.getUTCFullYear()}
							</Col>
						</FormGroup>
					</div>
				);
			})}
			<div className="confirm-button pull-right">
				<Button
					color="primary"
					size="sm"
					onClick={() => {
						onConfirm();
					}}
				>
					Confirm
				</Button>
			</div>
		</div>
	);
};
