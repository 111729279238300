import React, { useContext, useEffect, useState } from 'react';

import { Card, CardHeader, CardBody, CardTitle, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { HeaderFooterSection } from './partials/report-settings/HeaderFooterSection';

import { setting } from '../../api';
import UserContext from '../UserContext';
import { FaSpinner } from 'react-icons/fa';
import { SettingsReportsDB } from 'api/settings/reports';

export const ReportSettings = () => {
	const { jwt } = useContext(UserContext);

	const [activeTab, setActiveTab] = useState('header-footer');
	const [isLoaded, setIsLoaded] = useState(false);
	const [data, setData] = useState<SettingsReportsDB>();

	useEffect(() => {
		setting.reports
			.get(jwt)
			.then((settings) => {
				setData(settings);
				setIsLoaded(true);
			})
			.catch((e) => {
				// TODO: Handle better
				console.log('error');
				console.log(e);
			});
	}, []);

	const handleSaveClick = (data: SettingsReportsDB) => {
		return setting.reports.update(jwt, data).then((result) => {
			console.log(result);
		});
	};

	/**
	 * This function handles the click of a details tab
	 */
	const handleTabClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabName: 'header-footer') => {
		setActiveTab(tabName);

		// We don't want to have the actual <a> perform an action
		e.preventDefault();
	};

	return (
		<div className="content report-settings">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4">Report Settings</CardTitle>
						</CardHeader>
						<CardBody>
							{isLoaded ? (
								<>
									<div className="nav-tabs-navigation">
										<div className="nav-tabs-wrapper">
											<Nav id="tabs" role="tablist" tabs>
												<NavItem>
													<NavLink
														data-toggle="tab"
														href="#"
														role="tab"
														className={activeTab === 'header-footer' ? 'active' : ''}
														onClick={(e) => handleTabClick(e, 'header-footer')}
													>
														Header/Footer Preferences
													</NavLink>
												</NavItem>
											</Nav>
										</div>
									</div>
									<TabContent activeTab={activeTab}>
										<TabPane tabId="header-footer" role="tabpanel">
											<HeaderFooterSection key={`report-settings-header-footer-tab`} data={data!} onSave={handleSaveClick} />
										</TabPane>
									</TabContent>
								</>
							) : (
								<FaSpinner size={12} color="#ccc" className="fa-spin" />
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
