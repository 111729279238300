// Node modules
import * as Sentry from '@sentry/browser';

// Types
import { LogicOperator } from '@mouseware/lib-core';

// Exceptions
import { UnauthorizedException } from '../exceptions/UnauthorizedException';
import { InvalidParametersException } from '../exceptions/InvalidParametersException';
import { ResourceNotFoundException } from '../exceptions/ResourceNotFoundException';
import { ForbiddenException } from '../exceptions/ForbiddenException';
import { InternalServerErrorException } from '../exceptions/InternalServerErrorException';

// Helpers
import { retryUnauthorizedRequestAfterRefresh } from '..';

/**
 * Reports Response
 */
export interface ReportDB {
	docID: string;
	type: report_type;
	title: string;
	outputFormat: report_output_format;
	includeArchived: boolean;

	generate_key?: boolean;

	frequency: report_frequency;
	dayOfWeek?: string;
	dayOfMonth?: number;
	dayOfYear?: number;

	recipients?: Array<ReportNotificationToRecipient | ReportNotificationCCRecipient>;
	subject?: string;
	body?: string;

	columns: Array<ReportColumn>;

	last_run?: Date;
	notification_requires_approval?: boolean; // Whether or not the notification requires approval
}

/**
 * Notification To Recipient
 * Options are an email address ("address"), Any Non-Archived Member ("any-member"), or Non-Archived Administrator ("administrator")
 */
export interface ReportNotificationToRecipient {
	recipient_type: 'to';
	recipient: 'address' | 'any-member' | 'administrator';
	conditions?: Array<
		ReportNotificationRecipientConditionDatafield | ReportNotificationRecipientConditionValue | ReportNotificationRecipientConditionAdministratorRole
	>;
}

/**
 * Notification CC Recipient
 * Options are an email address ("address"), Any Non-Archived Member ("any-member"), or Non-Archived Administrator ("administrator")
 */
export interface ReportNotificationCCRecipient {
	recipient_type: 'cc';
	recipient: 'address' | 'any-member' | 'administrator';
	conditions?: Array<
		ReportNotificationRecipientConditionDatafield | ReportNotificationRecipientConditionValue | ReportNotificationRecipientConditionAdministratorRole
	>;
}

/**
 * Notification Recipient Datafield Condition
 * Valid only for the "Any Member" Recipient
 */
export interface ReportNotificationRecipientConditionDatafield {
	field_type: 'datafield';
	datafield_name: string;
	logicOperator: LogicOperator;
	value: string | boolean | number | Date | object;
}

/**
 * Notification Recipient Role Condition
 * Valid only for the "Administrator" Recipient
 */
export interface ReportNotificationRecipientConditionAdministratorRole {
	field_type: 'role';
	logicOperator: LogicOperator;
	value: string;
}

/**
 * Notification Recipient Value Condition
 * Valid for the "Any Member" and "Administrator" Recipients
 */
export interface ReportNotificationRecipientConditionValue {
	field_type: 'value';
	logicOperator: LogicOperator;
	value: string | boolean | number | Date;
}

/**
 * The Reports Column entry object
 */
export interface ReportColumn {
	type: 'field' | 'clearance';
	column: string; // The datafield or clearance uid
	conditions: Array<ReportColumnCondition>;
	name?: string; // Optional name override for this column of the report
	show?: boolean;
	sort?: 'asc' | 'desc'; // Only set if the column is the one being sorted by
}

/**
 * The Reports Column Conditions entry object
 */
export interface ReportColumnCondition {
	logicOperator: LogicOperator;
	value: string;
}

/**
 * The values for reporting type
 */
export enum report_type {
	AD_HOC = 'ad-hoc',
	RECURRING = 'recurring',
}

/**
 * The values for reporting output format
 */
export enum report_output_format {
	PDF = 'pdf',
	EXCEL = 'excel',
}

/**
 * The values for reporting frequency
 */
export enum report_frequency {
	DAILY = 'daily',
	WEEKLY = 'weekly',
	MONTHLY = 'monthly',
	ANNUALLY = 'annually',
}

/**
 * Database Reports Run Collection
 */
export interface ReportRunDB {
	docID?: string;
	status: report_run_status;

	requested_by: string;

	created_date: Date;
	completed_date?: Date;

	send_notification: boolean; // If the report has a notification to send, whether or not to send notification
	administrator_download: boolean; // If the report was triggered by an administrator, download it to their computer or not
	report_configuration: ReportDB; // The report configuration used to generate this report

	failed_date?: Date;
	failed_reason?: string;

	downloadUrl?: string;
}

/**
 * The values for the status
 */
export enum report_run_status {
	PENDING = 'pending',
	PROCESSING = 'processing',
	COMPLETED = 'completed',
	FAILED = 'failed',
}

export default class Reports {
	async list(token: string, attributes: { page?: number; page_size?: number; sort?: keyof ReportDB; order?: 'asc' | 'desc' } = {}): Promise<Array<ReportDB>> {
		// Validate the attributes
		attributes.page = attributes.page || 1;
		attributes.page_size = attributes.page_size || 100;
		attributes.sort = attributes.sort || undefined;
		attributes.order = attributes.order || undefined;

		const response = await fetch(
			`/api/v1/admin/reports?page=${attributes.page}&page_size=${attributes.page_size}${attributes.sort != null ? `&sort=${attributes.sort}` : ''}${
				attributes.order != null ? `&order=${attributes.order}` : ''
			}`,
			{
				headers: {
					Authorization: 'Bearer ' + token,
				},
			}
		);

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			if (respObj.report_list == null) {
				return [];
			}

			return respObj.report_list;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.list(newToken, attributes);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async get(token: string, docID: string): Promise<ReportDB> {
		const response = await fetch(`/api/v1/admin/reports/${docID}`, {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.get(newToken, docID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async create(token: string, data: ReportDB): Promise<ReportDB> {
		const response = await fetch(`/api/v1/admin/reports`, {
			method: 'POST',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify(data),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.create(newToken, data);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async update(token: string, docID: string, data: ReportDB): Promise<ReportDB> {
		const response = await fetch(`/api/v1/admin/reports/${docID}`, {
			method: 'PUT',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify(data),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.update(newToken, docID, data);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async delete(token: string, docID: string): Promise<void> {
		const response = await fetch(`/api/v1/admin/reports/${docID}`, {
			method: 'DELETE',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		if (response.status === 200) {
			return;
		} else if (response.status === 400) {
			const respObj = await response.json();
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.delete(newToken, docID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			const respObj = await response.json();
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			const respObj = await response.json();
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			const respObj = await response.json();
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async copy(token: string, docID: string): Promise<ReportDB> {
		const response = await fetch(`/api/v1/admin/reports/${docID}/copy`, {
			method: 'POST',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.copy(newToken, docID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async run(token: string, docID: string, data: { download_report: boolean; send_notification: boolean }): Promise<string> {
		const response = await fetch(`/api/v1/admin/reports/${docID}/run`, {
			method: 'POST',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify(data),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj.runID;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.run(newToken, docID, data);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async runStatus(token: string, docID: string, runID: string): Promise<ReportRunDB> {
		const response = await fetch(`/api/v1/admin/reports/${docID}/run/${runID}`, {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.runStatus(newToken, docID, runID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async runDownload(token: string, docID: string, runID: string): Promise<Blob> {
		const response = await fetch(`/api/v1/admin/reports/${docID}/run/${runID}/download`, {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		if (response.status === 200) {
			return await response.blob();
		} else if (response.status === 400) {
			const respObj = await response.json();
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.runDownload(newToken, docID, runID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			const respObj = await response.json();
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			const respObj = await response.json();
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			const respObj = await response.json();
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}
}
