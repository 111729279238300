import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';

import { Alert, Card, CardHeader, CardBody, CardTitle, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, FormGroup, Button } from 'reactstrap';

import { GeneralSection } from './GeneralSection';
import { PermissionsSection } from './PermissionsSection';

import { administrators } from '../../../../api';
import UserContext from '../../../UserContext';
import { FaSpinner } from 'react-icons/fa';
import { AdministratorRoleCategory, AdministratorRoleDB, AdministratorRolePermission } from 'api/administrators/roles';
import { generatePath, RouteComponentProps, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

interface Props extends RouteComponentProps {}

export const RoleDetails = (props: Props) => {
	const { match, history } = props;
	const { uid: docID } = useParams<{ uid?: string }>();

	const { jwt } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [loadingError, setLoadingError] = useState(false);
	const [activeTab, setActiveTab] = useState('general');
	const [saveError, setSaveError] = useState(null);
	const [hasFormChanged, setHasFormChanged] = useState(false);
	const [canSave, setCanSave] = useState(false);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);
	const [data, setData] = useState<AdministratorRoleDB>({ docID: '', organization: '', name: '', permissions: {} });
	const [permissions, setPermissions] = useState<{
		categories: Array<AdministratorRoleCategory>;
		roles: Array<AdministratorRolePermission>;
	}>({ categories: [], roles: [] });

	useEffect(() => {
		const getContents = async () => {
			setIsLoading(true);
			try {
				const roles = await administrators.roles.listPermissions(jwt);
				setPermissions(roles);

				if (docID != null && docID !== 'add') {
					const response = await administrators.roles.get(jwt, docID);
					setData(response);
				}
			} catch (error) {
				console.log('error');
				console.log(error);
				Sentry.captureException(error);
			} finally {
				setIsLoading(false);
			}
		};

		getContents();
	}, []);

	useEffect(() => {
		let canSaveTmp = false;

		if (data != null && data.name != null && data.name != '') {
			canSaveTmp = true;
		}

		setCanSave(canSaveTmp);
	}, [data]);

	const handleFormSave = async () => {
		setFormSaveInProgress(true);
		try {
			if (docID == null || docID === 'add') {
				const response = await administrators.roles.create(jwt, data);

				setHasFormChanged(false);
				setData(response);
				toast.success('Added Successfully');

				history.push({
					pathname: generatePath(match.path, {
						uid: response.docID,
					}),
				});
			} else {
				const response = await administrators.roles.update(jwt, data.docID, data);

				setHasFormChanged(false);
				setData(response);
				toast.success('Save Successful');
			}
		} catch (error) {
			console.log('error');
			console.log(error);
			Sentry.captureException(error);
		} finally {
			setFormSaveInProgress(false);
		}
	};

	async function handleTabClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabName: 'general' | 'permissions') {
		setActiveTab(tabName);

		// We don't want to have the actual <a> perform an action
		e.preventDefault();
	}

	async function handleSaveErrorCloseClick() {
		setSaveError(null);
	}

	async function onDataUpdate(fieldName: keyof AdministratorRoleDB, newValue: any) {
		const newData = { ...data };
		newData[fieldName] = newValue;

		setData(newData);
		setHasFormChanged(true);
	}

	return (
		<Row>
			<Col md="12">
				<Card>
					<CardHeader>
						<CardTitle tag="h4">{loadingError ? <div>Error</div> : isLoading ? <div>Loading</div> : data.name}</CardTitle>
					</CardHeader>
					<CardBody>
						{loadingError ? (
							<div>There was an error loading data.</div>
						) : isLoading ? (
							<div>Loading...</div>
						) : (
							<>
								{saveError != null ? (
									<Alert
										color={'danger'}
										toggle={() => {
											handleSaveErrorCloseClick();
										}}
									>
										{saveError}
									</Alert>
								) : (
									<></>
								)}
								<div className="nav-tabs-navigation">
									<div className="nav-tabs-wrapper">
										<Nav id="tabs" role="tablist" tabs>
											<NavItem>
												<NavLink
													data-toggle="tab"
													href="#"
													role="tab"
													className={activeTab === 'general' ? 'active' : ''}
													onClick={(e) => handleTabClick(e, 'general')}
												>
													General
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													data-toggle="tab"
													href="#"
													role="tab"
													className={activeTab === 'permissions' ? 'active' : ''}
													onClick={(e) => handleTabClick(e, 'permissions')}
												>
													Permissions
												</NavLink>
											</NavItem>
										</Nav>
									</div>
								</div>
								<TabContent activeTab={activeTab}>
									<TabPane tabId="general" role="tabpanel">
										<GeneralSection data={data} onChange={onDataUpdate} />
									</TabPane>
									<TabPane tabId="permissions" role="tabpanel">
										<PermissionsSection
											data={data != null && data.permissions != null ? data.permissions : {}}
											permissions={permissions}
											onChange={(data) => onDataUpdate('permissions', data)}
										/>
									</TabPane>
								</TabContent>
								<hr />
								<FormGroup check row>
									<Col sm={{ size: 10, offset: 2 }}>
										<Button color="primary" onClick={() => handleFormSave()} disabled={!hasFormChanged || formSaveInProgress}>
											{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Save'}
										</Button>
									</Col>
								</FormGroup>
							</>
						)}
					</CardBody>
				</Card>
			</Col>
		</Row>
	);
};
