import React, { useState, useEffect } from 'react';

import { Button, Col, FormGroup, Input, Label, Modal, Row } from 'reactstrap';

import { ReportNotificationCCRecipient, ReportNotificationToRecipient } from 'api/reports';
import { RecipientConditions } from './ReipientConditions/RecipientConditions';
import { LogicOperator } from '@mouseware/lib-core';
import { validateEmailAddress } from 'validator';
import { SettingsDataField } from 'api/settings/general';
import { validateRecipientAttrs } from 'components/Reports/helpers/validateReportSettings';

interface Props {
	show: boolean;
	onClose: () => void;
	onSave: (newValue: any) => void;
	editData?: ReportNotificationToRecipient | ReportNotificationCCRecipient;
	settingsDataFields: Array<SettingsDataField>;
}

export const RecipientDetailsOverlay = (props: Props) => {
	const { show, onSave, editData, settingsDataFields } = props;
	const isFieldEdit = editData != null ? true : false;

	const [canSave, setCanSave] = useState<Array<{ field: keyof ReportNotificationToRecipient | keyof ReportNotificationCCRecipient; message: string }>>([]);
	const [data, setData] = useState<ReportNotificationToRecipient | ReportNotificationCCRecipient>(
		editData != null
			? editData
			: {
					recipient_type: 'to',
					recipient: 'address',
					conditions: [],
			  }
	);

	useEffect(() => {
		if (editData != null) {
			setData(editData);
		}
	}, [editData]);

	useEffect(() => {
		setCanSave(validateRecipientAttrs(data));
	}, [data]);

	const handleOnCloseClick = () => {
		const { onClose } = props;

		return Promise.resolve()
			.then(() => {
				setData(
					editData != null
						? editData
						: {
								recipient_type: 'to',
								recipient: 'address',
								conditions: [],
						  }
				);
			})
			.then(() => {
				onClose();
			});
	};

	const handleInputFieldChange = (fieldName: keyof ReportNotificationToRecipient | keyof ReportNotificationCCRecipient, fieldValue: any) => {
		let newData = { ...data };
		(newData as any)[fieldName] = fieldValue;

		if (fieldName === 'recipient' && data.recipient !== fieldValue) {
			// Clear the conditions
			newData.conditions = [];
		}

		setData(newData);
	};

	const handleOnSaveClick = () => {
		onSave({ ...data });
		setData({ recipient_type: 'to', recipient: 'address', conditions: [] });
	};

	return (
		<Modal
			className="modal-lg"
			isOpen={show}
			toggle={() => {
				handleOnCloseClick();
			}}
		>
			<div className="modal-header">
				<h5 className="modal-title">{isFieldEdit ? `Edit` : `Add`} Recipient</h5>
			</div>
			<div className="modal-body">
				<Row>
					<Col md={3}>
						<FormGroup>
							<Label for="recipient_type">Type</Label>
							<Input
								type="select"
								name="recipient_type"
								id="recipient_type"
								onChange={(e) => handleInputFieldChange('recipient_type', e.target.value)}
								value={data.recipient_type != null ? data.recipient_type : 'to'}
							>
								<option value={''}>- Select Type -</option>
								<option value="to">To</option>
								<option value="cc">CC</option>
							</Input>
						</FormGroup>
					</Col>
					<Col md={5}>
						<FormGroup>
							<Label for="recipient">Recipient</Label>
							<Input
								type="select"
								name="recipient"
								id="recipient"
								onChange={(e) => handleInputFieldChange('recipient', e.target.value)}
								defaultValue={data.recipient != null ? data.recipient : 'address'}
							>
								<option value={''}>- Select Recipient -</option>
								<option value="address">Email Address</option>
								<option value="any-member">Member</option>
								<option value="administrator">Administrator</option>
							</Input>
						</FormGroup>
					</Col>
					{data.recipient === 'address' && (
						<Col md={4}>
							<FormGroup>
								<Label for="recipient">Address</Label>
								<Input
									type="text"
									name="value"
									id="value"
									onChange={(e) => {
										handleInputFieldChange(
											'conditions',
											data.conditions != null && data.conditions.length === 1
												? [{ ...data.conditions[0], value: e.target.value }]
												: [{ field_type: 'value', logicOperator: LogicOperator.EQUAL_TO, value: e.target.value }]
										);
									}}
									value={data.conditions != null && data.conditions.length === 1 ? (data.conditions[0].value as string) : ''}
								/>
							</FormGroup>
						</Col>
					)}
				</Row>
				{data.recipient !== 'address' && (
					<Row>
						<Col md={12}>
							<FormGroup>
								<Label for="conditions">Conditions</Label>
								<RecipientConditions
									recipient={data.recipient}
									onUpdate={(newValue: any) => handleInputFieldChange('conditions', newValue)}
									conditions={data.conditions}
									settingsDataFields={settingsDataFields}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
			</div>
			<div className="modal-footer justify-content-center">
				<Button
					className="btn-round"
					color="primary"
					data-dismiss="modal"
					type="button"
					disabled={canSave.length > 0}
					onClick={() => {
						handleOnSaveClick();
					}}
				>
					{isFieldEdit ? `Update` : `Add`}
				</Button>
				<Button
					className="btn-round"
					color="secondary"
					data-dismiss="modal"
					type="button"
					onClick={() => {
						handleOnCloseClick();
					}}
				>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};
