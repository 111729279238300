import React, { useEffect, useState } from 'react';

import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { AdminNavbar } from '../../components/Navbars/AdminNavbar';
import { Footer } from '../../components/Footer/Footer';
import { Sidebar } from '../../components/Sidebar/Sidebar';

import routes, { RouteEntry } from '../../routes';

interface Props extends RouteComponentProps {}

export const AdminPage = (props: Props) => {
	const [sidebarMini, setSidebarMini] = useState(false);

	useEffect(() => {
		if (navigator.platform.indexOf('Win') > -1) {
			document.documentElement.className += ' perfect-scrollbar-on';
			document.documentElement.classList.remove('perfect-scrollbar-off');
		}

		return () => {
			if (navigator.platform.indexOf('Win') > -1) {
				document.documentElement.className += ' perfect-scrollbar-off';
				document.documentElement.classList.remove('perfect-scrollbar-on');
			}
		};
	}, []);

	const getRoutes = (routes: Array<RouteEntry>): Array<React.ReactNode> => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views!);
			}

			return <Route path={'/administrator' + prop.path} component={prop.component as any} key={key} />;
		});
	};

	const handleMiniClick = () => {
		if (document.body.classList.contains('sidebar-mini')) {
			setSidebarMini(false);
		} else {
			setSidebarMini(true);
		}
		document.body.classList.toggle('sidebar-mini');
	};

	return (
		<div className="wrapper">
			<Sidebar {...props} routes={routes} />
			<div className="main-panel">
				<AdminNavbar {...props} handleMiniClick={handleMiniClick} />
				<Switch>
					{getRoutes(routes)}
					<Route exact path="" render={() => <Redirect to="/administrator/dashboard" />} />
				</Switch>
				<Toaster position="bottom-right" />
				<Footer fluid />
			</div>
		</div>
	);
};
