import React, { useEffect, useContext } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';

import UserContext from '../../../UserContext';

import { frontend } from '../../../../api';
import { FaSpinner } from 'react-icons/fa';

interface Props extends RouteComponentProps {
	clearancesRootPath: string;
}

export const DocumentDownload = (props: Props) => {
	const { user } = useContext(UserContext);
	const { clearancesRootPath, history, match } = props;
	const { documentID } = useParams<{ documentID: string }>();

	/**
	 * This function will retrieve the clearance settings data from the backend
	 */
	useEffect(() => {
		const getContents = async () => {
			try {
				const downloadFileObj = await frontend.downloadClearanceFile(user!.token!, documentID);

				// Create blob link to download
				const url = window.URL.createObjectURL(downloadFileObj.buffer);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${downloadFileObj.filename}`);

				// Append to html page
				document.body.appendChild(link);

				// Force download
				link.click();

				// Clean up and remove the link
				link.parentNode!.removeChild(link);

				history.push(clearancesRootPath);
			} catch (err) {
				// TODO: Handle better
				console.log('error');
				console.log(err);
			}
		};

		getContents();
	}, []);

	return (
		<div className="file-processing">
			<div className="processing-content">
				<FaSpinner size={12} className="fa-spin" />
			</div>
		</div>
	);
};
