// Node modules
import * as Sentry from '@sentry/browser';

// Types
export interface AdministratorUserDB {
	docID: string;
	organization?: string; // This is for multi-tenancy
	role: string;

	email: string;

	name: string;
	password: string;

	preferences?: AdministratorPreferences;

	attributes?: AdministratorUserAttributes;

	archived: null | Date; // The date that this user was archived
}

/**
 * Administrator attributes
 */
export interface AdministratorUserAttributes {
	canModify?: boolean; // Default is "true" meaning that an item can be modified
	canDelete?: boolean; // Default is "true" meaning that an item can be deleted
	canLogin?: boolean; // Default is "true" meaning they have the ability to login
	isLocked?: boolean; // Default is "false" meaning the account is not locked and the user can login

	requirePasswordReset?: boolean; // Default is "false" meaning no password reset is required to login
	lastPasswordChange?: Date; // The last time the password was changed. No value = never changed.

	passwordResetRequestCount?: number; // Default is "0" meaning if password reset count reaches a threshold the account becomes locked. Reset by an administrator password reset request
	invalidLoginAttemptCount?: number; // Default is "0" meaning if a invalid login attempt count reaches a threshold then the account becomes locked
}

/**
 * Administrator preferences
 */
export interface AdministratorPreferences {
	[key: string]: AdministratorPreferences | string;
}

// Exceptions
import { UnauthorizedException } from '../exceptions/UnauthorizedException';
import { InvalidParametersException } from '../exceptions/InvalidParametersException';
import { ResourceNotFoundException } from '../exceptions/ResourceNotFoundException';
import { ForbiddenException } from '../exceptions/ForbiddenException';
import { InternalServerErrorException } from '../exceptions/InternalServerErrorException';
import { ResourceAlreadyArchivedException } from '../exceptions/ResourceAlreadyArchivedException';
import { ConflictException } from '../exceptions/ConflictException';

// Helpers
import { retryUnauthorizedRequestAfterRefresh } from '..';

export default class AdministratorsUsers {
	async list(token: string): Promise<Array<AdministratorUserDB>> {
		const response = await fetch(`/api/v1/admin/administrators/users`, {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj.records;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.list(newToken);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async get(token: string, docID: string): Promise<AdministratorUserDB> {
		const response = await fetch(`/api/v1/admin/administrators/users/${docID}`, {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.get(newToken, docID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async create(token: string, data: AdministratorUserDB): Promise<AdministratorUserDB> {
		const response = await fetch(`/api/v1/admin/administrators/users`, {
			method: 'POST',
			headers: {
				Authorization: 'Bearer ' + token,
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.create(newToken, data);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 409) {
			throw new ConflictException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async update(token: string, docID: string, data: AdministratorUserDB): Promise<AdministratorUserDB> {
		const response = await fetch(`/api/v1/admin/administrators/users/${docID}`, {
			method: 'PUT',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify(data),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.update(newToken, docID, data);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async requestPasswordReset(token: string, docID: string): Promise<void> {
		const response = await fetch(`/api/v1/admin/administrators/users/${docID}/password-reset`, {
			method: 'PUT',
			headers: {
				Authorization: 'Bearer ' + token,
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.requestPasswordReset(newToken, docID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async unlock(token: string, docID: string): Promise<void> {
		const response = await fetch(`/api/v1/admin/administrators/users/${docID}/unlock`, {
			method: 'PUT',
			headers: {
				Authorization: 'Bearer ' + token,
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.unlock(newToken, docID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async archive(token: string, docID: string): Promise<void> {
		const response = await fetch(`/api/v1/admin/administrators/users/${docID}/archive`, {
			method: 'PUT',
			headers: {
				Authorization: 'Bearer ' + token,
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.archive(newToken, docID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 470) {
			const respObj = await response.json();
			throw new ResourceAlreadyArchivedException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}
}
