import React, { useState } from 'react';

import { Input, Form, Label, Button, Col, FormGroup } from 'reactstrap';
import { FaSpinner } from 'react-icons/fa';
import { SettingsReportsDB } from 'api/settings/reports';

interface Props {
	onSave: (newData: SettingsReportsDB) => void;
	data: SettingsReportsDB;
}

export const HeaderFooterSection = (props: Props) => {
	const { onSave } = props;
	const [data, setData] = useState(props.data);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);

	const handleInputFieldChange = (fieldName: keyof SettingsReportsDB, newValue: any) => {
		const newData = { ...data };
		data[fieldName] = newValue;
		setData(data);
	};

	const handleSaveClick = () => {
		return Promise.resolve()
			.then(() => {
				setFormSaveInProgress(true);
			})
			.then(() => {
				return onSave(data);
			})
			.then(() => {
				setFormSaveInProgress(false);
			});
	};

	return (
		<div className="report-settings-header-footer-preferences-section">
			<Form>
				<FormGroup row>
					<Label for="headerText" sm={2}>
						Header Text
					</Label>
					<Col sm={10}>
						<Input
							type="text"
							name="headerText"
							id="headerText"
							placeholder="Enter Header Text"
							value={data.header_text != null ? data.header_text : ''}
							onChange={(e) => {
								handleInputFieldChange('header_text', e.target.value);
							}}
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="footerText" sm={2}>
						Footer Text
					</Label>
					<Col sm={10}>
						<Input
							type="text"
							name="footerText"
							id="footerText"
							placeholder="Enter Footer Text"
							value={data.footer_text != null ? data.footer_text : ''}
							onChange={(e) => {
								handleInputFieldChange('footer_text', e.target.value);
							}}
						/>
					</Col>
				</FormGroup>
				<FormGroup check row>
					<Col sm={{ size: 10, offset: 2 }}>
						<Button color="primary" onClick={() => handleSaveClick()} disabled={formSaveInProgress}>
							{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Save'}
						</Button>
					</Col>
				</FormGroup>
			</Form>
		</div>
	);
};
