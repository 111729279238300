// Node modules
import * as Sentry from '@sentry/browser';

// Types
import { SettingAttributesEntry } from '.';
import { LogicOperator } from '@mouseware/lib-core';
import { Events } from '@mouseware/lib-events';

// Exceptions
import { UnauthorizedException } from '../exceptions/UnauthorizedException';
import { InvalidParametersException } from '../exceptions/InvalidParametersException';
import { ResourceNotFoundException } from '../exceptions/ResourceNotFoundException';
import { ForbiddenException } from '../exceptions/ForbiddenException';
import { InternalServerErrorException } from '../exceptions/InternalServerErrorException';

// Helpers
import { retryUnauthorizedRequestAfterRefresh } from '..';

/**
 * Database Settings Notifications Doc
 */
export interface SettingsNotificationsDB {
	from_name: string;
	from_email: string;
	reply_to_email: string;
	senders: Array<SettingsNotificationsSenderEntry>;
	notification_list: Array<SettingsNotificationsListEntry>;
}

/**
 *
 */
export interface SettingsNotificationsSenderEntry {
	uid: string;
	name: string;
	provider: string;
	is_default: boolean;
	provider_options?: { [key: string]: any };

	fallback_to?: string; // UID of the sender
}

/**
 *
 */
export interface SettingsNotificationsListEntry {
	uid: string;
	name: string;
	event: Events;

	require_approval?: boolean; // False unless explicity set to true

	recipients: Array<SettingsNotificationsListEntryRecipient>;

	subject: string;
	body: string;

	attributes?: SettingAttributesEntry;
}

/**
 *
 */
export interface SettingsNotificationsListEntryRecipient {
	recipient_type: 'to' | 'cc';
	recipient: 'user' | 'any-member' | 'administrator';
	conditions?: Array<SettingsNotificationsListEntryRecipientCondition>;
}

/**
 *
 */
export interface SettingsNotificationsListEntryRecipientCondition {
	field_type: 'datafield' | 'clearance' | 'value';
	field: 'document-expiration' | string; // String is dynamic data field
	logicOperator: LogicOperator;
	value_type: 'user' | 'value' | 'role';
	value: any;
}

export default class SettingsNotifications {
	async get(token: string): Promise<SettingsNotificationsDB> {
		const response = await fetch(`/api/v1/admin/settings/notifications`, {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.get(newToken);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 403) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async getSenderGmail(token: string): Promise<SettingsNotificationsDB> {
		const response = await fetch(`/api/v1/admin/settings/notifications/sender/gmail`, {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.get(newToken);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 403) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async create(token: string, data: SettingsNotificationsDB): Promise<void> {
		const response = await fetch(`/api/v1/admin/settings/notifications`, {
			method: 'POST',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify(data),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.create(newToken, data);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 403) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async update(token: string, data: SettingsNotificationsDB): Promise<void> {
		const response = await fetch(`/api/v1/admin/settings/notifications`, {
			method: 'PUT',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify(data),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.create(newToken, data);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 403) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}
}
