import React, { useState, useContext, useEffect } from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';

import UserContext from '../UserContext';
import { CheckClearances } from './CheckClearances';
import { FrontendStep2 } from './MemberVerification';
import { FrontendStep3 } from './MemberDashboard';

import { AuthMember, frontend } from '../../api';

interface Props extends RouteComponentProps {}

export const Frontend = (props: Props) => {
	const { signIn } = useContext(UserContext);
	const { history } = props;
	const [userData, setUserData] = useState<{ bsaId?: string; dob?: string; loginToken?: string; verificationCodeEmail?: string }>({});
	const [loginMessage, setLoginMessage] = useState<string | null>(null);

	useEffect(() => {
		Promise.resolve().then(() => {
			// Retreive the report list from the backend
			return frontend
				.getMessage()
				.then((message) => {
					if (message != null) {
						setLoginMessage(message);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		});
	}, []);

	const onVerifyStepClick = (data: { bsaId?: string; dob?: string; loginToken?: string; verificationCodeEmail?: string }) => {
		setUserData(data);
		history.push('/app/verify');
	};

	const handleLoginResult = (data: AuthMember) => {
		signIn(data);
	};

	return (
		<div className="frontend-page">
			<Switch>
				<Route
					exact
					path="/app/login"
					render={(props) => <CheckClearances {...props} onVerifyStepClick={onVerifyStepClick} loginMessage={loginMessage} />}
				/>
				<Route path="/app/verify" render={(props) => <FrontendStep2 {...props} data={userData} signIn={handleLoginResult} />} />
				<Route path="/app/dashboard" render={(props) => <FrontendStep3 {...props} />} />
				<Redirect to="/app/dashboard" />
			</Switch>
		</div>
	);
};
