import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation, RouteComponentProps } from 'react-router-dom';

import { Button, Table, UncontrolledTooltip, Alert } from 'reactstrap';

import { notification } from '../../../api';
import UserContext from '../../UserContext';

import { NotificationDetailsOverlay } from '../helpers/NotificationDetailsOverlay';
import { FaSpinner } from 'react-icons/fa';
import { NotificationDB } from 'api/notifications';

interface Props extends RouteComponentProps {}

export const FailedSection = (props: Props) => {
	const { jwt } = useContext(UserContext);

	const { history } = props;

	const location = useLocation();
	const params = useParams<{ notificationID: string }>();
	const notificationID = params.notificationID;

	const [isLoaded, setIsLoaded] = useState(false);
	const [data, setData] = useState<Array<NotificationDB>>([]);
	const [tableRecordDetailsData, setTableRecordDetailsData] = useState<NotificationDB>();
	const basePath = Object.values(params).reduce((path, param) => path.replace('/' + param, ''), location.pathname);

	/**
	 * This function will retrieve the notifications data from the backend
	 */
	useEffect(() => {
		notification
			.getFailed(jwt)
			.then((notifications) => {
				setData(notifications);
				setIsLoaded(true);
			})
			.catch((e) => {
				// TODO: Handle better
				console.log('error');
				console.log(e);
			});
	}, []);

	/**
	 * This function will update the overlay based on the notification id
	 */
	useEffect(() => {
		if (notificationID != null) {
			if (data != null) {
				const details = data.filter((row) => row.docID === notificationID)[0];
				setTableRecordDetailsData(details);
			}
		} else {
			setTableRecordDetailsData(undefined);
		}
	}, [isLoaded, notificationID]);

	const handleDetailsOnCloseClick = () => {
		history.push(`${basePath}`);
	};

	/**
	 * This function handles the view details button for a record
	 */
	const handleViewDetailsClick = (docID: string) => {
		history.push(`${basePath}/${docID}`);
	};

	/**
	 * The render function for the React component
	 */
	return (
		<>
			<NotificationDetailsOverlay show={tableRecordDetailsData != null} data={tableRecordDetailsData!} onClose={handleDetailsOnCloseClick} />
			{isLoaded ? (
				data != null && data.length > 0 ? (
					<Table responsive style={{ marginBottom: 0 }}>
						<thead className="text-primary">
							<tr>
								<th className="text-left">Sender</th>
								<th className="text-center">Date</th>
								<th className="text-center">To</th>
								<th className="text-center">Subject</th>
								<th className="text-right">Actions</th>
							</tr>
						</thead>
						<tbody>
							{data.map((row, key) => {
								const parsedTo = row.to != null ? row.to.split(',') : [];
								return (
									<tr key={`notifications-failed-section-data-field-${row.docID}`}>
										<td>{row.sender != null ? row.sender : 'Default'}</td>
										<td className="text-center">{row.created}</td>
										<td className="text-center">{parsedTo.join(', ')}</td>
										<td className="text-center">{row.subject}</td>
										<td className="text-right">
											<Button
												className="btn-icon"
												color="info"
												id={`notifications-failed-section-data-field-${key}-details`}
												size="sm"
												type="button"
												onClick={() => handleViewDetailsClick(row.docID)}
											>
												<i className="fa fa-file" />
											</Button>
											<UncontrolledTooltip delay={0} target={`notifications-failed-section-data-field-${key}-details`}>
												View Details
											</UncontrolledTooltip>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				) : (
					<Alert color="secondary">
						<span>No records</span>
					</Alert>
				)
			) : (
				<FaSpinner size={12} color="#ccc" className="fa-spin" />
			)}
		</>
	);
};
