// Node modules
import * as Sentry from '@sentry/browser';

// Types
import { AuthAdministrator } from 'api';

// Exceptions
import { LoginUnsuccessfulException } from '../exceptions/LoginUnsuccessfulException';
import { UnauthorizedException } from '../exceptions/UnauthorizedException';
import { InvalidParametersException } from '../exceptions/InvalidParametersException';
import { ResourceNotFoundException } from '../exceptions/ResourceNotFoundException';
import { ForbiddenException } from '../exceptions/ForbiddenException';
import { InternalServerErrorException } from '../exceptions/InternalServerErrorException';
import { PasswordDoesNotMeetMinimumRequirements } from './exceptions/PasswordDoesNotMeetMinimumRequirements';
import { AccountIsLockedException } from './exceptions/AccountIsLockedException';
import { ResetPasswordCodeNotFoundException } from './exceptions/ResetPasswordCodeNotFoundException';

export default class Login {
	async login(username: string, password: string): Promise<string> {
		const response = await fetch(`/api/v1/admin/auth/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				uid: username,
				password: password,
			}),
		});

		const responseJSON = await response.json();

		if (response.status === 200) {
			// Success response
			return responseJSON.auth_token;
		} else if (response.status === 401) {
			throw new UnauthorizedException('Request response returned unauthorized');
		} else if (response.status === 404) {
			throw new LoginUnsuccessfulException('Invalid credentials provided');
		} else if (response.status === 400) {
			throw new LoginUnsuccessfulException(responseJSON.message);
		} else if (response.status === 461) {
			throw new LoginUnsuccessfulException(responseJSON.message);
		} else if (response.status === 481) {
			throw new LoginUnsuccessfulException(responseJSON.message);
		} else if (response.status === 482) {
			throw new LoginUnsuccessfulException(responseJSON.message);
		} else if (response.status === 471) {
			throw new LoginUnsuccessfulException(responseJSON.message);
		} else if (response.status === 472) {
			await this.forgotPassword(username);
			throw new LoginUnsuccessfulException('Password has expired. Check email for link to reset.');
		} else if (response.status === 500) {
			throw new LoginUnsuccessfulException(responseJSON.message);
		} else {
			Sentry.captureException(responseJSON);
			throw new Error('Unknown error');
		}
	}

	async login2fa(token: string, code: string): Promise<{ access: AuthAdministrator; refresh: string }> {
		const response = await fetch(`/api/v1/admin/auth/verify`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				token: token,
				code: code,
			}),
		});

		const responseJSON = await response.json();

		if (response.status === 200) {
			// Success response
			return responseJSON;
		} else if (response.status === 401) {
			throw new UnauthorizedException('Request response returned unauthorized');
		} else if (response.status === 404) {
			throw new LoginUnsuccessfulException('Invalid credentials provided');
		} else if (response.status === 400) {
			throw new LoginUnsuccessfulException(responseJSON.message);
		} else if (response.status === 461) {
			throw new LoginUnsuccessfulException(responseJSON.message);
		} else if (response.status === 481) {
			throw new LoginUnsuccessfulException(responseJSON.message);
		} else if (response.status === 482) {
			throw new LoginUnsuccessfulException(responseJSON.message);
		} else if (response.status === 471) {
			throw new LoginUnsuccessfulException(responseJSON.message);
		} else if (response.status === 472) {
			throw new LoginUnsuccessfulException('Password has expired.');
		} else if (response.status === 500) {
			throw new LoginUnsuccessfulException(responseJSON.message);
		} else {
			throw new Error('Unknown error');
		}
	}

	async forgotPassword(username: string): Promise<string> {
		const response = await fetch(`/api/v1/admin/auth/requestPasswordReset`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				uid: username,
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			throw new UnauthorizedException('Request response retry returned unauthorized');
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async resetPassword(resetCode: string, password: string): Promise<void> {
		const response = await fetch(`/api/v1/admin/auth/passwordReset`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				id: resetCode,
				password: password,
			}),
		});

		if (response.status === 200) {
			return await response.json();
		} else if (response.status === 401) {
			throw new UnauthorizedException('Request response returned unauthorized');
		} else if (response.status === 400) {
			const responseObj = await response.json();
			throw new InvalidParametersException(responseObj.message);
		} else if (response.status === 404) {
			const responseObj = await response.json();
			throw new ResetPasswordCodeNotFoundException(responseObj.message);
		} else if (response.status === 461) {
			throw new AccountIsLockedException('Account is Locked');
		} else if (response.status === 462) {
			const responseObj = await response.json();

			if (responseObj.requirements != null) {
				throw new PasswordDoesNotMeetMinimumRequirements(
					`Password does not meet minimum requirements: ${responseObj.requirements.join(', ')}. You must request a new password reset.`
				);
			} else {
				throw new PasswordDoesNotMeetMinimumRequirements('Password does not meet minimum requirements. You must request a new password reset.');
			}
		} else if (response.status === 500) {
			throw new Error('Server error');
		} else {
			throw new Error('Unknown error');
		}
	}
}
