import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, RouteComponentProps } from 'react-router-dom';

import { TemplateDetails } from './template-helpers/TemplateDetails';
import { ListTemplates } from './template-helpers/ListTemplates';
import { TemplateUpload } from './template-helpers/TemplateUpload';
import { TemplateHistory } from './template-helpers/TemplateHistory/TemplateHistory';
import { SettingsDataField } from 'api/settings/general';
import { SettingsClearancesType } from 'api/settings/clearances';

interface Props extends RouteComponentProps {
	clearanceFields: Array<SettingsClearancesType>;
	dynamicSettingsDataFields: Array<SettingsDataField>;
	memberImportRootPath: string;
}

export const ImportTemplates = (props: Props) => {
	const { match, clearanceFields, dynamicSettingsDataFields, memberImportRootPath } = props;
	let { path, url } = useRouteMatch();

	return (
		<div className="member-import-templates">
			<Switch>
				<Route
					path={`${path}/:templateID/upload`}
					render={(props) => (
						<TemplateUpload {...props} memberImportRootPath={memberImportRootPath} dynamicSettingsDataFields={dynamicSettingsDataFields} />
					)}
				/>
				<Route path={`${path}/:templateID/history`} render={(props) => <TemplateHistory {...props} />} />
				<Route path={`${path}/:templateID`} render={(props) => <TemplateDetails {...props} dynamicSettingsDataFields={dynamicSettingsDataFields} />} />
				<Route
					path={path}
					render={(props) => <ListTemplates {...props} clearanceFields={clearanceFields} dynamicSettingsDataFields={dynamicSettingsDataFields} />}
				/>
			</Switch>
		</div>
	);
};
