import React, { useContext, useState } from 'react';

import { Button } from 'reactstrap';

import { Dropzone } from '../../../Dropzone';

import { member } from '../../../../api';
import UserContext from '../../../UserContext';
import { FaSpinner } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router-dom';
import { FileWithAttributes } from 'api/tempTypes';

interface Props extends RouteComponentProps {
	templateID: string;
	memberImportRootPath: string;
	validFileTypes: string | Array<string>;
}

export const UploadBulkMemberFile = (props: Props) => {
	const { templateID, memberImportRootPath, validFileTypes, history } = props;
	const { jwt } = useContext(UserContext);

	const [showProcessingModal, setShowProcessingModal] = useState(false);

	/**
	 * This function handles a file being uploaded
	 * @param {*} acceptedFiles the array of file objects
	 */
	const handleUploadedFile = async (acceptedFiles: Array<FileWithAttributes>) => {
		if (acceptedFiles instanceof Array) {
			const formData = new FormData();
			formData.append('files', acceptedFiles[0]);

			setShowProcessingModal(true);
			const response = await member.import.importMembers(jwt, formData, templateID);
			if (response != null) {
				// Lets redirect
				history.push(`${memberImportRootPath}/${response}`);
			}
		} else {
			console.log('unknown acceptedFiles type', Object.prototype.toString.call(acceptedFiles));
		}
	};

	const downloadTemplateClick = async () => {
		try {
			const blob = await member.import.getImportDocTemplate(jwt);

			// Create blob link to download
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Template.xlsx`);

			// Append to html page
			document.body.appendChild(link);

			// Force download
			link.click();

			// Clean up and remove the link
			link.parentNode!.removeChild(link);
		} catch (err) {
			throw err;
		}
	};

	return (
		<>
			{showProcessingModal ? (
				<div className="file-processing">
					<div className="processing-content">
						<FaSpinner size={12} className="fa-spin" />
					</div>
				</div>
			) : (
				<>
					<Dropzone onDrop={handleUploadedFile} validTypes={validFileTypes} />
					<div className="download-template">
						<Button color="link" size="sm" onClick={() => downloadTemplateClick()}>
							Download template
						</Button>
					</div>
				</>
			)}
		</>
	);
};
