import React from 'react';

import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import { SettingsClearancesType } from 'api/settings/clearances';
import { FileWithAttributes } from 'api/tempTypes';

interface Props {
	files: Array<FileWithAttributes>;
	onAttributeSelect: (fileIndex: number, attribute: string, value: any) => void;
	onNextStepClick: () => void;
	clearanceSettings: Array<SettingsClearancesType>;
}

export const SelectAttributes = (props: Props) => {
	const { files, onAttributeSelect, onNextStepClick, clearanceSettings } = props;

	/**
	 * This will verify that the attributes were selected
	 */
	const verifyAttributeSelection = () => {
		let valid = false;

		const clearanceSettingsUids = clearanceSettings.map((clearance) => {
			return clearance.uid;
		});

		const todayDate = new Date();
		todayDate.setHours(0, 0, 0, 0);

		for (const file of files) {
			if (file.attributes != null) {
				const validTypeSelection = clearanceSettingsUids.includes(file.attributes['type']);
				const certificationDate = new Date(file.attributes.certification_date);

				if (validTypeSelection === true && certificationDate <= todayDate) {
					valid = true;
				}
			}
		}

		if (valid === true) {
			onNextStepClick();
		}
	};

	console.log('clearanceSettings', clearanceSettings);
	return (
		<div className="select-attributes">
			<Form>
				{files.map((file, index) => {
					return (
						<div key={`member-details-overlay-upload-document-select-attributes-${index}`}>
							<h6>{file.name}</h6>
							<FormGroup row>
								<Label for={`member-details-overlay-upload-document-select-attributes-type-${index}`} sm={2}>
									Type
								</Label>
								<Col sm={10}>
									<Input
										type="select"
										name={`member-details-overlay-upload-document-select-attributes-type-${index}`}
										id={`member-details-overlay-upload-document-select-attributes-type-${index}`}
										onChange={(e) => onAttributeSelect(index, 'type', e.target.value)}
										defaultValue={file.attributes.type}
									>
										<option value={null as any}>- Select Type -</option>
										{clearanceSettings
											.filter(
												(clearance) =>
													clearance.attributes != null &&
													(clearance.attributes.readonly == null || clearance.attributes.readonly !== true)
											)
											.map((clearance) => {
												const { uid, name } = clearance;

												return (
													<option key={uid} value={uid}>
														{name}
													</option>
												);
											})}
									</Input>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for={`member-details-overlay-upload-document-select-attributes-certification-date-${index}`} sm={2}>
									Certification Date
								</Label>
								<Col sm={10}>
									<Input
										type="date"
										name={`member-details-overlay-upload-document-select-attributes-certification-date-${index}`}
										id={`member-details-overlay-upload-document-select-attributes-certification-date-${index}`}
										onChange={(e) => onAttributeSelect(index, 'certification_date', e.target.value)}
									/>
								</Col>
							</FormGroup>
						</div>
					);
				})}
			</Form>
			<div className="next-button pull-right">
				<Button
					color="primary"
					size="sm"
					onClick={() => {
						verifyAttributeSelection();
					}}
				>
					Next
				</Button>
			</div>
		</div>
	);
};
