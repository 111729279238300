import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';

import { Button, ButtonGroup, CardTitle, UncontrolledTooltip } from 'reactstrap';

import { TableList } from './documents-tab/TableList';
import { UploadDocument } from './documents-tab/UploadDocument';
import { SelectAttributes } from './documents-tab/SelectAttributes';
import { ConfirmUpload } from './documents-tab/ConfirmUpload';

import { setting, member } from '../../../../api';
import UserContext from '../../../UserContext';
import { SettingsClearancesType } from 'api/settings/clearances';
import { FileWithAttributes } from 'api/tempTypes';

interface Props {
	uid: string;
	closeModalControl: (state: boolean) => void;
}

export const DocumentsTab = (props: Props) => {
	const { uid, closeModalControl } = props;
	const { jwt } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isTableList, setIsTableList] = useState(true);
	const [isUploadDocument, setIsUploadDocument] = useState(false);
	const [viewAllDocuments, setViewAllDocuments] = useState(false);
	const [clearanceSettings, setClearanceSettings] = useState<Array<SettingsClearancesType>>([]);
	const [data, setData] = useState([]);
	const [uploadState, setUploadState] = useState(1);
	const [uploadedFiles, setUploadedFiles] = useState<Array<FileWithAttributes>>([]);

	useEffect(() => {
		Promise.resolve().then(() => {
			// Retreive the dynamic fields from the backend
			return setting.clearances
				.get(jwt)
				.then((settings) => {
					setClearanceSettings(settings.types_list);
				})
				.catch((e) => {
					// TODO: Handle better
					console.log('error');
					console.log(e);
				});
		});
	}, []);

	const toggleViewAllDocumentsClick = () => {
		setViewAllDocuments(!viewAllDocuments);
	};

	const handleAddButtonClick = () => {
		setIsTableList(false);
		setIsUploadDocument(true);
		closeModalControl(true);
	};

	const handleAddButtonClose = () => {
		setIsTableList(true);
		setIsUploadDocument(false);
		closeModalControl(false);
	};

	const handleUploadDocument = (acceptedFiles: Array<FileWithAttributes>) => {
		if (acceptedFiles instanceof Array) {
			const file = acceptedFiles[0];
			file.attributes = {};
			uploadedFiles.push(file);

			setUploadState(2);
			setUploadedFiles(uploadedFiles);
		} else {
			console.log('unknown acceptedFiles type', Object.prototype.toString.call(acceptedFiles));
		}
	};

	const handleAttributeNextStepClick = () => {
		setUploadState(3);
	};

	const handleAttributeSelect = (fileIndex: number, attribute: string, value: any) => {
		let tmp = uploadedFiles;

		tmp[fileIndex].attributes[attribute] = value;

		setUploadedFiles(tmp);
	};

	const handleConfirmUploadStepClick = async () => {
		try {
			const response = await uploadFiles();

			setUploadState(1);
			setUploadedFiles([]);
			setIsTableList(true);
			setIsUploadDocument(false);
			closeModalControl(false);
		} catch (error) {
			console.log('error');
			console.log(error);
			Sentry.captureException(error);
		}
	};

	const uploadFiles = () => {
		const formData = new FormData();
		let attributes: any = [];
		uploadedFiles.forEach((file) => {
			let obj: any = {};
			obj[file.name] = file.attributes;

			attributes.push(obj);
		});

		console.log('uploading files...');
		formData.append('file-attributes', JSON.stringify(attributes));
		formData.append('files', uploadedFiles[0]);

		try {
			console.log('SENDING');
			return member.document
				.post(jwt, uid, formData)
				.then((data) => {
					return true;
				})
				.catch((error) => {
					console.log(JSON.stringify(error));
				});
		} catch (error) {
			console.log('HIT Error');
			return false;
		}
	};

	return (
		<div className="documents-tab">
			{!isUploadDocument ? (
				<ButtonGroup className="pull-right">
					<Button
						color="info"
						size="sm"
						className="btn-icon"
						id={`member-details-documents-tab-add-doc-button`}
						onClick={() => handleAddButtonClick()}
					>
						<i className="fa fa-plus" />
					</Button>
					<Button
						color="secondary"
						size="sm"
						className="btn-icon"
						id={`member-details-documents-tab-view-all-docs-button`}
						onClick={() => toggleViewAllDocumentsClick()}
					>
						<i className={`fa fa-list-ul`} />
					</Button>
					<UncontrolledTooltip delay={0} target={`member-details-documents-tab-add-doc-button`}>
						Add New Document
					</UncontrolledTooltip>
					<UncontrolledTooltip delay={0} target={`member-details-documents-tab-view-all-docs-button`}>
						View All Documents
					</UncontrolledTooltip>
				</ButtonGroup>
			) : (
				<></>
			)}
			<CardTitle tag="h4" style={{ marginTop: 0, marginBottom: '.5rem' }}>
				Documents {isUploadDocument ? '> Upload' : <></>}
			</CardTitle>
			{isTableList ? (
				<TableList key={uid} uid={uid} clearanceSettings={clearanceSettings} viewAllDocuments={viewAllDocuments} />
			) : isUploadDocument ? (
				<>
					{uploadState === 1 ? (
						<UploadDocument
							key={uid}
							onBackButtonClick={() => handleAddButtonClose()}
							onDrop={(acceptedFiles) => handleUploadDocument(acceptedFiles)}
							validFileTypes={'image/*'}
						/>
					) : uploadState === 2 ? (
						<SelectAttributes
							files={uploadedFiles}
							onNextStepClick={() => handleAttributeNextStepClick()}
							onAttributeSelect={handleAttributeSelect}
							clearanceSettings={clearanceSettings}
						/>
					) : uploadState === 3 ? (
						<ConfirmUpload clearanceSettings={clearanceSettings} files={uploadedFiles} onConfirm={() => handleConfirmUploadStepClick()} />
					) : (
						<span>???</span>
					)}
				</>
			) : (
				<></>
			)}
		</div>
	);
};
