import React from 'react';
import * as Sentry from '@sentry/react';

interface Props {}

interface State {
	hasError: boolean;
}

/**
 * This has to be a class component
 */
export class ErrorHandler extends React.Component {
	public state: State = {
		hasError: false,
	};

	constructor(props: Props) {
		super(props);

		this.state = { hasError: false };
	}

	componentDidCatch(error: Error, info: React.ErrorInfo) {
		// Display fallback UI
		this.setState({ hasError: true });
		Sentry.captureException(error);
	}

	render() {
		const { hasError } = this.state;

		if (hasError) {
			return <h1>Something went wrong.</h1>;
		}

		return this.props.children;
	}
}
