import React, { useState } from 'react';

import { Button, Modal, Form, Input, FormGroup, Label, ModalHeader } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import { ValidFileExtension } from 'api/settings/clearances';

interface Props {
	show: boolean;
	editRecord: ValidFileExtension;
	onClose: () => void;
	onSave: (newData: ValidFileExtension) => void;
}

export const ClearanceFileExtensionOverlay = (props: Props) => {
	const [data, setData] = useState<ValidFileExtension>(
		props.editRecord != null ? props.editRecord : { uid: '', name: '', mime_type: '', extension: '', attributes: {} }
	);

	const handleOnCloseClick = () => {
		return Promise.resolve()
			.then(() => {
				setData({ uid: '', name: '', extension: '', mime_type: '', attributes: {} });
			})
			.then(() => {
				props.onClose();
			});
	};

	const handleInputFieldChange = (fieldName: keyof ValidFileExtension, newValue: any) => {
		let newData = { ...data };

		newData[fieldName] = newValue;

		setData(newData);
	};

	const handleOnSaveClick = () => {
		let uidValue = data.uid;

		if (uidValue == null) {
			uidValue = data.name.replace(/\s+/g, '-').toLowerCase();
		}

		return Promise.resolve()
			.then(() => {
				return props.onSave({ uid: uidValue, name: data.name, extension: data.extension, mime_type: data.mime_type, attributes: {} });
			})
			.then(() => {
				setData({ uid: '', name: '', extension: '', mime_type: '', attributes: {} });
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<Modal isOpen={props.show} toggle={() => handleOnCloseClick()}>
			<ModalHeader toggle={() => handleOnCloseClick()}>{data.uid == null ? `Add` : `Edit`} Field</ModalHeader>
			<div className="modal-body">
				<Form>
					<FormGroup>
						<Label for="fieldName">Name</Label>
						<Input
							type="text"
							name="fieldName"
							id="fieldName"
							placeholder="Enter Name"
							onChange={(e) => handleInputFieldChange('name', e.target.value)}
							value={data['name']}
						/>
					</FormGroup>
					<FormGroup>
						<Label for="fieldExtension">Extension</Label>
						<Input
							type="text"
							name="fieldExtension"
							id="fieldExtension"
							placeholder="Enter Extension"
							onChange={(e) => handleInputFieldChange('extension', e.target.value)}
							value={data['extension']}
						/>
					</FormGroup>
					<FormGroup>
						<Label for="fieldMimeType">Mime-Type</Label>
						<Input
							type="text"
							name="fieldMimeType"
							id="fieldMimeType"
							placeholder="Enter Mime-Type"
							onChange={(e) => handleInputFieldChange('mime_type', e.target.value)}
							value={data['mime_type']}
						/>
					</FormGroup>
				</Form>
			</div>
			<div className="modal-footer justify-content-center">
				<Button className="btn-round" color="primary" data-dismiss="modal" type="button" onClick={() => handleOnSaveClick()}>
					Add
				</Button>
				<Button className="btn-round" color="secondary" data-dismiss="modal" type="button" onClick={() => handleOnCloseClick()}>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};
