import React from 'react';

import { Button, FormGroup, Row, Col, UncontrolledTooltip, Alert } from 'reactstrap';

import { ConditionsSectionAddModify } from './conditions-section/ConditionsSectionAddModify/ConditionsSectionAddModify';
import { SettingsNotificationsListEntryRecipientCondition } from 'api/settings/notifications';
import { SettingsDataField } from 'api/settings/general';
import { SettingsClearancesType } from 'api/settings/clearances';

interface Props {
	recipient: 'user' | 'any-member' | 'administrator';
	conditionsData: Array<SettingsNotificationsListEntryRecipientCondition>;
	dynamicDataFields: Array<SettingsDataField>;
	clearanceFields: Array<SettingsClearancesType>;
	handleInputFieldChange: (newValue: any) => void;
	handleCanSaveChange: (newValue: any) => void;
}

export const ConditionsSection = (props: Props) => {
	const { recipient, conditionsData, dynamicDataFields, clearanceFields, handleInputFieldChange, handleCanSaveChange } = props;

	const selectedField = '';

	const handleDeleteConditionClick = (index: number) => {
		let newConditionsData = [...conditionsData];
		newConditionsData.splice(index, 1);

		handleInputFieldChange(newConditionsData);
	};

	return (
		<>
			{conditionsData != null && conditionsData.length > 0 ? (
				selectedField != null ? (
					<>
						{conditionsData.map((row, key) => {
							let fieldTypeValue = 'value';
							let fieldValue = '';

							switch (row.field_type) {
								case 'value':
									fieldTypeValue = 'value';
									fieldValue = 'Document Expiration';
									break;
								case 'datafield':
									fieldTypeValue = 'field';
									fieldValue = dynamicDataFields.filter((field) => field.uid === row.field)[0].name;
									break;
								case 'clearance':
									fieldTypeValue = 'clearance';

									break;
							}

							let logicOperatorValue = '';
							switch (row.logicOperator) {
								case 'equal-to':
									logicOperatorValue = 'equal to';
									break;
								case 'not-equal-to':
									logicOperatorValue = 'not equal to';
									break;
								case 'contains':
									logicOperatorValue = 'contains';
									break;
								case 'starts-with':
									logicOperatorValue = 'starts with';
									break;
								case 'ends-with':
									logicOperatorValue = 'ends with';
									break;
							}

							let valueTypeValue = '';
							switch (row.value_type) {
								case 'user':
									valueTypeValue = "user's";
									break;
								case 'value':
									valueTypeValue = 'value';
									break;
							}

							let valueValue = row.value;

							switch (row.field_type) {
								case 'value':
									if (row.value_type === 'user') {
										valueValue = dynamicDataFields.filter((field) => field.uid === row.value)[0].name;
									}
									break;
								case 'datafield':
									if (row.value_type === 'user') {
										valueValue = dynamicDataFields.filter((field) => field.uid === row.value)[0].name;
									}
									break;
								case 'clearance':
									valueValue = clearanceFields.filter((field) => field.uid === row.value)[0].name;
									break;
							}

							return (
								<Row key={`column-attributes-section-details-overlay-condition-${key}`}>
									<Col md={10}>
										{key > 0 ? 'and' : 'Where'} {fieldTypeValue} {fieldValue} is {logicOperatorValue} the {valueTypeValue} "{valueValue}"
									</Col>
									<Col md={2}>
										<FormGroup className="pull-right">
											<Button
												className="btn-icon"
												color="danger"
												id={`column-attributes-section-details-overlay-condition-${key}-delete`}
												size="sm"
												type="button"
												onClick={() => handleDeleteConditionClick(key)}
											>
												<i className="fa fa-times" />
											</Button>
											<UncontrolledTooltip delay={0} target={`column-attributes-section-details-overlay-condition-${key}-delete`}>
												Remove
											</UncontrolledTooltip>
										</FormGroup>
									</Col>
								</Row>
							);
						})}
					</>
				) : (
					<Alert color="secondary">
						<span>Could not load conditions.</span>
					</Alert>
				)
			) : (
				<Alert color="secondary">
					<span>No conditions defined</span>
				</Alert>
			)}
			<ConditionsSectionAddModify
				recipient={recipient}
				conditionsData={conditionsData}
				dynamicDataFields={dynamicDataFields}
				clearanceFields={clearanceFields}
				onSave={(record) => {
					let newData = conditionsData;
					if (newData == null) {
						newData = [];
					}

					newData.push(record);
					handleInputFieldChange(newData);
				}}
				handleCanSaveChange={handleCanSaveChange}
			/>
		</>
	);
};
