import React from 'react';
import { Switch, Route, NavLink as RouterNavLink, Redirect, useRouteMatch, RouteComponentProps } from 'react-router-dom';

import { Card, CardHeader, CardBody, Form, Nav, NavLink, NavItem, Col, Row, TabContent, TabPane } from 'reactstrap';

import { ClearancesTab } from './dashboard/ClearancesTab';
import { DetailsTab } from './dashboard/DetailsTab';

interface Props extends RouteComponentProps {}

export const FrontendStep3 = (props: Props) => {
	const { match, history } = props;

	return (
		<Form action="" className="form" method="">
			<Card className="card-dashboard">
				<CardHeader>
					<CardHeader>
						<h3 className="header text-center">Dashboard</h3>
					</CardHeader>
				</CardHeader>
				<CardBody>
					<Row>
						<Col lg="3" md="3" sm="3" xs="4">
							<div className="nav-tabs-navigation verical-navs p-0">
								<div className="nav-tabs-wrapper">
									<Nav id="tabs" className="flex-column nav-stacked" role="tablist" tabs>
										<NavItem key={`details-nav-item`}>
											<NavLink tag={RouterNavLink} to={`${match.path}/details`} exact>
												Personal Details
											</NavLink>
										</NavItem>
										<NavItem key={`clearances-nav-item`}>
											<NavLink tag={RouterNavLink} to={`${match.path}/clearances`} exact>
												Clearances
											</NavLink>
										</NavItem>
									</Nav>
								</div>
							</div>
						</Col>
						<Col lg="9" md="9" sm="9" xs="8">
							<TabContent>
								<TabPane>
									<Switch>
										<Route exact path={match.path}>
											<Redirect push to={`${match.path}/details`} />
										</Route>
										<Route
											exact
											path={`${match.path}/details`}
											render={(props) => <DetailsTab {...props} key={`member-dashboard-details-tab`} history={history} />}
										></Route>
										<Route
											path={`${match.path}/clearances`}
											render={(props) => (
												<ClearancesTab {...props} dashboardRootPath={match.path} key={`member-dashboard-documents-tab`} />
											)}
										/>
									</Switch>
								</TabPane>
							</TabContent>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Form>
	);
};
