// Node modules
import * as Sentry from '@sentry/browser';

// Types

// Exceptions
import { InvalidContentException } from '@mouseware/lib-core';

// Helpers

/**
 * Validates input string to determine if it is in the format of an email address
 * @param {string} inputStr
 */
export const validateEmailAddress = (inputStr: string) => {
	const tester = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

	if (tester.test(inputStr)) {
		return true;
	}

	return false;
};

/**
 * Validates input string to determine if it is in the format of a date
 * @param {string} inputStr
 * @param {string} dateFormatStr
 */
export const validateDate = (inputStr: string, dateFormatStr: string = `YYYY-MM-DD`) => {
	let tester = null;

	if (dateFormatStr === 'YYYY-MM-DD') {
		tester = /^(\d{4})[-/]?(\d{2})[-/]?(\d{2})$/;
	} else if (dateFormatStr === 'MM-DD-YYYY') {
		tester = /^(\d{2})[-/]?(\d{2})[-/]?(\d{4})$/;
	}

	if (tester == null) {
		console.log(`Validator.validateDate() tester is null`);
		return false;
	}

	if (tester.test(inputStr)) {
		return true;
	}

	return false;
};

/**
 * Validates input string to determine if it is in the format of a date
 * @param {string} inputStr
 * @param {string} dateFormatStr
 */
export const validateISODate = (inputStr: string) => {
	const tester = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

	if (tester.test(inputStr)) {
		const date = new Date(inputStr);

		if (date.toISOString() === inputStr) {
			return true;
		}
	}

	return false;
};

/**
 * Validates input string to determine if it is in the format of a date
 * @param {string} inputStr
 * @param {string} dateFormatStr
 */
export const convertToDateObject = (inputStr: string, currentDateFormatStr: string = `YYYY-MM-DD`) => {
	let date = null;

	if (currentDateFormatStr === 'YYYY-MM-DD') {
		const parts: any = inputStr.match(/^(\d{4})[-/]?(\d{2})[-/]?(\d{2})$/);

		if (parts == null || parts.length !== 4) {
			throw new InvalidContentException('Invalid date object provided', { parts: parts });
		}

		// Format: Year, Month, Day, Hour, Minute, Second
		date = new Date(Date.UTC(parts[1], parts[2] - 1, parts[3], 0, 0, 0));
	} else if (currentDateFormatStr === 'MM-DD-YYYY') {
		const parts: any = inputStr.match(/^(\d{2})[-/]?(\d{2})[-/]?(\d{4})$/);

		if (parts == null || parts.length !== 4) {
			throw new InvalidContentException('Invalid date object provided', { parts: parts });
		}

		// Format: Year, Month, Day, Hour, Minute, Second
		date = new Date(Date.UTC(parts[3], parts[2] - 1, parts[1], 0, 0, 0));
	}

	if (date == null) {
		console.log('Validator.convertToDateObject() date is null');
		Sentry.captureMessage('Validator.convertToDateObject() date is null');
	}

	return date;
};

/**
 * Validates input string to determine if it is in the format of a string
 * @param {string} inputStr
 */
export const validateString = (inputStr: string) => {
	let tester = /^[\w\s\d-')(.]*$/;

	if (tester.test(inputStr)) {
		return true;
	}

	return false;
};

/**
 * Validates input string to determine if it is in the format of an integer
 * @param {string} inputStr
 */
export const validateInt = (inputStr: string) => {
	let tester = /^[\d]*$/;

	if (tester.test(inputStr)) {
		return true;
	}

	return false;
};
