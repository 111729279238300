import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';

import { Button, Modal, Row, Col, Input, FormGroup, Label, ModalHeader } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import { SettingsClearancesType } from 'api/settings/clearances';

interface Props {
	show: boolean;
	editRecord: SettingsClearancesType;
	onSave: (newData: SettingsClearancesType) => void;
	onClose: () => void;
}

export const ClearanceRecordOverlay = (props: Props) => {
	const { editRecord, onSave } = props;

	const [data, setData] = useState<SettingsClearancesType>(
		editRecord != null ? editRecord : { uid: '', name: '', attributes: { has_expiration: false, readonly: false }, archived: null }
	);

	const handleOnCloseClick = () => {
		return Promise.resolve()
			.then(() => {
				return setData({ uid: '', name: '', attributes: { has_expiration: false, readonly: false }, archived: null });
			})
			.then(() => {
				props.onClose();
			});
	};

	const handleInputFieldChange = (fieldName: keyof SettingsClearancesType, newValue: any) => {
		let newData = { ...data };

		if (newData.attributes == null) {
			newData.attributes = { readonly: false, has_expiration: false };
		}

		if (fieldName === 'name') {
			newData[fieldName] = newValue;
		}

		setData(newData);
	};

	const handleOnSaveClick = () => {
		if (data.uid == null) {
			data.uid = data.name.replace(/\s+/g, '-').toLowerCase();
		}

		try {
			onSave(data);
			setData({ uid: '', name: '', attributes: { readonly: false, has_expiration: false }, archived: null });
		} catch (error) {
			Sentry.captureException(error);
		}
	};

	return (
		<Modal className="modal-lg" isOpen={props.show} toggle={() => handleOnCloseClick()}>
			<ModalHeader toggle={() => handleOnCloseClick()}>{data.uid == null ? `Add` : `Edit`} Field</ModalHeader>
			<div className="modal-body">
				<Row>
					<Col md={5}>
						<FormGroup>
							<Label for="name">Name</Label>
							<Input
								type="text"
								name="name"
								id="name"
								placeholder="Enter Name"
								onChange={(e) => handleInputFieldChange('name', e.target.value)}
								value={data.name != null ? data.name : ''}
							/>
						</FormGroup>
					</Col>
					<Col md={3}>
						<FormGroup>
							<Label for="readonly">Readonly</Label>
							<Input
								type="select"
								name="readonly"
								id="readonly"
								onChange={(e) => handleInputFieldChange('attributes', e.target.value)}
								defaultValue={data.attributes != null && data.attributes.readonly != null ? data.attributes.readonly : (false as any)}
							>
								<option value={true as any}>Yes</option>
								<option value={false as any}>No</option>
							</Input>
						</FormGroup>
					</Col>
					<Col md={4}>
						<FormGroup>
							<Label for="has_expiration">Expires</Label>
							<Input
								type="select"
								name="has_expiration"
								id="has_expiration"
								onChange={(e) => handleInputFieldChange('attributes', e.target.value)}
								defaultValue={
									data.attributes != null && data.attributes.has_expiration != null ? data.attributes.has_expiration : (false as any)
								}
							>
								<option value={true as any}>Yes</option>
								<option value={false as any}>No</option>
							</Input>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{data.attributes != null && data.attributes.has_expiration === true && (
						<Col md={3} className="offset-md-9">
							<FormGroup>
								<Label for="expiration_years">Expires in (years)</Label>
								<Input
									type="number"
									name="expiration_years"
									id="expiration_years"
									onChange={(e) => handleInputFieldChange('attributes', e.target.value)}
									value={data.attributes != null && data.attributes.expiration_years != null ? data.attributes.expiration_years : ''}
								/>
							</FormGroup>
						</Col>
					)}
				</Row>
			</div>
			<div className="modal-footer justify-content-center">
				<Button className="btn-round" color="primary" data-dismiss="modal" type="button" onClick={handleOnSaveClick}>
					{data.uid == null ? `Add` : `Update`}
				</Button>
				<Button className="btn-round" color="secondary" data-dismiss="modal" type="button" onClick={handleOnCloseClick}>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};
