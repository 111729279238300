import React, { useState, useEffect, useContext } from 'react';

import { Button, Table, UncontrolledTooltip, Alert } from 'reactstrap';

import { notification } from '../../../api';
import UserContext from '../../UserContext';

import { NotificationDetailsOverlay } from '../helpers/NotificationDetailsOverlay';
import { FaSpinner } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router-dom';
import { NotificationDB } from 'api/notifications';

interface Props extends RouteComponentProps {}

export const PendingSection = (props: Props) => {
	const { jwt } = useContext(UserContext);

	const [isLoaded, setIsLoaded] = useState(false);
	const [data, setData] = useState<Array<NotificationDB>>([]);
	const [tableRecordDetailsData, setTableRecordDetailsData] = useState<NotificationDB>();

	/**
	 * This function will retrieve the notifications data from the backend
	 */
	useEffect(() => {
		notification
			.getPending(jwt)
			.then((notifications) => {
				setData(notifications);
				setIsLoaded(true);
			})
			.catch((e) => {
				// TODO: Handle better
				console.log('error');
				console.log(e);
			});
	}, []);

	const handleDetailsOnCloseClick = (removeRecord = false) => {
		if (removeRecord === true) {
			let rowToRemove = null;

			if (data != null) {
				data.forEach((row, index) => {
					if (row.docID === tableRecordDetailsData!.docID) {
						rowToRemove = index;
					}
				});

				if (rowToRemove != null) {
					const newData = [...data];
					newData.splice(rowToRemove, 1);
					setData(newData);
				}
			}
		}

		setTableRecordDetailsData(undefined);
	};

	return (
		<>
			<NotificationDetailsOverlay show={tableRecordDetailsData != null} data={tableRecordDetailsData!} onClose={handleDetailsOnCloseClick} />
			{isLoaded ? (
				data != null && data.length > 0 ? (
					<Table responsive style={{ marginBottom: 0 }}>
						<thead className="text-primary">
							<tr>
								<th className="text-left">Sender</th>
								<th className="text-center">To</th>
								<th className="text-center">Subject</th>
								<th className="text-right">Actions</th>
							</tr>
						</thead>
						<tbody>
							{data.map((row, key) => {
								const parsedTo = row.to != null ? row.to.split(',') : [];
								return (
									<tr key={`notifications-pending-section-data-field-${row.docID}`}>
										<td>{row.sender != null ? row.sender : 'Default'}</td>
										<td className="text-center">{parsedTo.join(', ')}</td>
										<td className="text-center">{row.subject}</td>
										<td className="text-right">
											<Button
												className="btn-icon"
												color="info"
												id={`notifications-pending-section-data-field-${key}-details`}
												size="sm"
												type="button"
												onClick={() => setTableRecordDetailsData(row)}
											>
												<i className="fa fa-file" />
											</Button>
											<UncontrolledTooltip delay={0} target={`notifications-pending-section-data-field-${key}-details`}>
												View Details
											</UncontrolledTooltip>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				) : (
					<Alert color="secondary">
						<span>No records</span>
					</Alert>
				)
			) : (
				<FaSpinner size={12} color="#ccc" className="fa-spin" />
			)}
		</>
	);
};
