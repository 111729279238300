import React, { useState, useEffect } from 'react';

import { Button, Card, CardHeader, CardBody, CardFooter, Form, Input, InputGroupText, InputGroup } from 'reactstrap';

import { AuthMember, frontend } from '../../api';
import { validateInt } from '../../validator';
import { FaKey, FaSpinner } from 'react-icons/fa';

interface Props {
	data: { bsaId?: string; dob?: string; loginToken?: string; verificationCodeEmail?: string };
	signIn: (user: AuthMember) => void;
}

export const FrontendStep2 = (props: Props) => {
	const { signIn, data: userData } = props;
	const [checkButtonEnabled, setCheckButtonEnabled] = useState(false);
	const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
	const [data, setData] = useState<{ verificationCode?: string }>({});

	/**
	 * This function will check to make sure that we are good to submit the form anytime that data is changed
	 */
	useEffect(() => {
		let canSave = true;

		if (data.verificationCode == null || data.verificationCode === '') {
			canSave = false;
		}

		if (!validateInt(data.verificationCode!)) {
			canSave = false;
		}

		setCheckButtonEnabled(canSave);
	}, [data]);

	/**
	 * This function handles the update of data
	 * @param fieldName the field to udpate
	 * @param newValue the new data object
	 */
	const handleInputFieldChange = (fieldName: 'verificationCode', newValue: any) => {
		const newData = { ...data };
		newData[fieldName] = newValue;
		setData(newData);
	};

	/**
	 * This function handles moving to the next step
	 */
	const handleVerifyClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();

		Promise.resolve()
			.then(() => {
				setIsSubmitInProgress(true);
			})
			.then(() => {
				return frontend.verify(userData.loginToken!, data.verificationCode!);
			})
			.then((result) => {
				signIn(result);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<Form action="" className="form" method="">
			<Card className="card-login">
				<CardHeader>
					<CardHeader>
						<h3 className="header text-center">Verification</h3>
					</CardHeader>
				</CardHeader>
				<CardBody>
					Please check the email associated with the account{userData != null ? `(${userData.verificationCodeEmail}) ` : ''} and enter the
					verification code below.
					<InputGroup>
						<InputGroupText style={{ background: 'none' }}>
							<FaKey color="#66615b" />
						</InputGroupText>
						<Input
							type="number"
							name={'verificationCode'}
							id={'verificationCode'}
							autoFocus
							placeholder="Verification Code"
							onChange={(e) => handleInputFieldChange('verificationCode', e.target.value)}
						/>
					</InputGroup>
					If you don't have access to this account, contact {process.env.REACT_APP_CLIENT_SUPORT_EMAIL}
				</CardBody>
				<CardFooter>
					<Button
						block
						className="btn-round mb-3"
						color="primary"
						type="button"
						disabled={isSubmitInProgress || (!isSubmitInProgress && !checkButtonEnabled)}
						onClick={(e) => handleVerifyClick(e)}
					>
						{isSubmitInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Verify'}
					</Button>
				</CardFooter>
			</Card>
		</Form>
	);
};
