import React from 'react';
import { Switch, Route, useRouteMatch, RouteComponentProps } from 'react-router-dom';

import { CardTitle } from 'reactstrap';

import { DocumentList } from './clearances-tab/DocumentList';
import { DocumentUpload } from './clearances-tab/DocumentUpload';
import { DocumentDownload } from './clearances-tab/DocumentDownload';

interface Props extends RouteComponentProps {
	dashboardRootPath: string;
}

export const ClearancesTab = (props: Props) => {
	const { dashboardRootPath, match } = props;
	const { path } = useRouteMatch();

	return (
		<div className="clearances-tab">
			<CardTitle tag="h4" style={{ marginTop: 0, marginBottom: '.5rem' }}>
				Clearances
			</CardTitle>
			<Switch>
				<Route
					path={`${match.path}/upload`}
					render={(props) => (
						<DocumentUpload
							{...props}
							clearancesRootPath={`${dashboardRootPath}/clearances`}
							key={`member-dashboard-clearances-tab-document-upload`}
						/>
					)}
				/>
				<Route
					path={`${match.path}/download/:documentID`}
					render={(props) => (
						<DocumentDownload
							{...props}
							clearancesRootPath={`${dashboardRootPath}/clearances`}
							key={`member-dashboard-clearances-tab-document-download`}
						/>
					)}
				/>
				<Route exact path={`${match.path}`} render={(props) => <DocumentList {...props} key={`member-dashboard-clearances-tab-document-list`} />} />
			</Switch>
		</div>
	);
};
