// Node modules
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// Types

// Exceptions

// Helpers

export const initialize = () => {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [new BrowserTracing()],

		tracesSampleRate: 0.2,
	});
};

export const setUser = (userId: string) => {
	Sentry.configureScope((scope) => {
		scope.setUser({
			id: userId,
		});
	});
};
