import React, { useContext, useEffect, useState } from 'react';
import { generatePath, RouteComponentProps, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import {
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	FormGroup,
	Button,
	UncontrolledTooltip,
} from 'reactstrap';

import { GeneralSection } from './General/GeneralSection';
import { ColumnAttributesSection } from './Columns/ColumnsSection';
import { NotificationsSection } from './Notifications/NotificationsSection';

import { setting, report } from '../../../api';
import UserContext from '../../UserContext';
import toast from 'react-hot-toast';
import { ReportDB, report_frequency, report_output_format, report_type } from 'api/reports';
import { SettingsDataField } from 'api/settings/general';
import { SettingsClearancesType } from 'api/settings/clearances';
import { FadeLoader } from 'react-spinners';
import { validateReportSettings } from '../helpers/validateReportSettings';
import { FaExclamationTriangle, FaSpinner } from 'react-icons/fa';

interface Props extends RouteComponentProps {}

export const ReportDetails = (props: Props) => {
	const { location, match, history } = props;
	const { uid: docID, tabID } = useParams<{ uid?: string; tabID?: 'general' | 'notifications' | 'columns' }>();

	const { jwt } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [loadingError, setLoadingError] = useState(null);
	const [canSave, setCanSave] = useState<Array<{ field: keyof ReportDB; message: string }>>([]);
	const [hasFormChanged, setHasFormChanged] = useState(false);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);
	const [activeTab, setActiveTab] = useState<'general' | 'notifications' | 'columns'>(tabID != null ? tabID : 'general');
	const [dynamicSettingsDataFields, setDynamicSettingsDataFields] = useState<Array<SettingsDataField>>([]);
	const [clearancesData, setClearancesData] = useState<Array<SettingsClearancesType>>([]);
	const [data, setData] = useState<ReportDB>({
		docID: '',
		title: '',
		outputFormat: report_output_format.PDF,
		columns: [],
		type: report_type.AD_HOC,
		includeArchived: false,
		frequency: report_frequency.WEEKLY,
	});

	useEffect(() => {
		const getContents = async () => {
			setIsLoading(true);
			try {
				const genSettings = await setting.general.get(jwt);
				setDynamicSettingsDataFields(genSettings.data_fields);

				const clearSettings = await setting.clearances.get(jwt);
				setClearancesData(clearSettings.types_list);

				if (docID != null && docID !== 'add') {
					const response = await report.get(jwt, docID);

					// Make sure we have the columns defined
					if (response.columns == null) {
						response.columns = [];
					}

					setData(response);
				}
			} catch (error) {
				console.log('error');
				console.log(error);
				Sentry.captureException(error);
			} finally {
				setIsLoading(false);
			}
		};

		getContents();
	}, []);

	useEffect(() => {
		setCanSave(validateReportSettings(data));
	}, [data]);

	const handleFormSave = async () => {
		setFormSaveInProgress(true);
		try {
			if (docID == null || docID === 'add') {
				const response = await report.create(jwt, data);

				setHasFormChanged(false);
				setData(response);
				toast.success('Added Successfully');

				history.push({
					pathname: generatePath(match.path, {
						uid: response.docID,
						tabID: tabID || 'general',
					}),
				});
			} else {
				const response = await report.update(jwt, data.docID, data);

				setHasFormChanged(false);
				setData(response);
				toast.success('Save Successful');
			}
		} catch (err: any) {
			if (err.message != null) {
				toast.error(err.message);
			} else {
				toast.error('Error saving');
				Sentry.captureException(err);
			}
		} finally {
			setFormSaveInProgress(false);
		}
	};

	const handleTabClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabName: 'general' | 'notifications' | 'columns') => {
		setActiveTab(tabName);
		history.replace({
			pathname: tabName,
		});

		// We don't want to have the actual <a> perform an action
		e.preventDefault();
	};

	const onDataUpdate = (fieldName: keyof ReportDB, newValue: any): void => {
		const newData = { ...data };
		(newData[fieldName] as any) = newValue;

		if (fieldName === 'type' && newValue === 'recurring' && newData.frequency == null) {
			newData.frequency = report_frequency.MONTHLY;
		}

		setHasFormChanged(true);
		setData(newData);
	};

	if (tabID == null || !['general', 'notifications', 'columns'].includes(tabID)) {
		history.push({
			pathname: `${location.pathname}/general`,
		});
	}

	return (
		<Row>
			<Col md="12">
				<Card>
					{isLoading ? (
						<CardBody className="d-flex justify-content-center align-items-center">
							<FadeLoader />
						</CardBody>
					) : (
						<>
							<CardHeader>
								<CardTitle tag="h4">
									{loadingError ? (
										<div>Error</div>
									) : isLoading ? (
										<div>Loading</div>
									) : data.title != null && data.title !== '' ? (
										data.title
									) : docID === 'add' ? (
										'Add Report'
									) : (
										''
									)}
								</CardTitle>
							</CardHeader>
							<CardBody>
								{loadingError ? (
									<div>There was an error loading data.</div>
								) : (
									<>
										<div className="nav-tabs-navigation">
											<div className="nav-tabs-wrapper">
												<Nav id="tabs" role="tablist" tabs>
													<NavItem>
														<NavLink
															data-toggle="tab"
															href="#"
															role="tab"
															className={activeTab === 'general' ? 'active' : ''}
															onClick={(e) => handleTabClick(e, 'general')}
														>
															General{' '}
															{canSave != null &&
															canSave.filter((record) => ['title', 'type', 'outputFormat'].includes(record.field)).length > 0 ? (
																<>
																	<span id={`tab-general-error`}>
																		<FaExclamationTriangle />
																	</span>
																	<UncontrolledTooltip delay={0} target={`tab-general-error`}>
																		{canSave
																			.filter((record) => ['title', 'type', 'outputFormat'].includes(record.field))
																			.map((record, index) => (
																				<div key={index}>
																					{record.message}
																					<br />
																				</div>
																			))}
																	</UncontrolledTooltip>
																</>
															) : (
																<></>
															)}
														</NavLink>
													</NavItem>
													<NavItem>
														<NavLink
															data-toggle="tab"
															href="#"
															role="tab"
															className={activeTab === 'notifications' ? 'active' : ''}
															onClick={(e) => handleTabClick(e, 'notifications')}
														>
															Notifications{' '}
															{canSave != null &&
															canSave.filter((record) => ['recipients', 'subject', 'body'].includes(record.field)).length > 0 ? (
																<>
																	<span id={`tab-notifications-error`}>
																		<FaExclamationTriangle />
																	</span>
																	<UncontrolledTooltip delay={0} target={`tab-notifications-error`}>
																		{canSave
																			.filter((record) => ['recipients', 'subject', 'body'].includes(record.field))
																			.map((record, index) => (
																				<div key={index}>
																					{record.message}
																					<br />
																				</div>
																			))}
																	</UncontrolledTooltip>
																</>
															) : (
																<></>
															)}
														</NavLink>
													</NavItem>
													<NavItem>
														<NavLink
															data-toggle="tab"
															href="#"
															role="tab"
															className={activeTab === 'columns' ? 'active' : ''}
															onClick={(e) => handleTabClick(e, 'columns')}
														>
															Columns{' '}
															{canSave != null && canSave.filter((record) => ['columns'].includes(record.field)).length > 0 ? (
																<>
																	<span id={`tab-columns-error`}>
																		<FaExclamationTriangle />
																	</span>
																	<UncontrolledTooltip delay={0} target={`tab-columns-error`}>
																		{canSave
																			.filter((record) => ['columns'].includes(record.field))
																			.map((record, index) => (
																				<div key={index}>
																					{record.message}
																					<br />
																				</div>
																			))}
																	</UncontrolledTooltip>
																</>
															) : (
																<></>
															)}
														</NavLink>
													</NavItem>
												</Nav>
											</div>
										</div>
										<TabContent activeTab={activeTab}>
											<TabPane tabId="general" role="tabpanel">
												<GeneralSection data={data} onChange={onDataUpdate} />
											</TabPane>
											<TabPane tabId="notifications" role="tabpanel">
												<NotificationsSection settingsDataFields={dynamicSettingsDataFields} data={data} onChange={onDataUpdate} />
											</TabPane>
											<TabPane tabId="columns" role="tabpanel">
												<ColumnAttributesSection
													settingsDataFields={dynamicSettingsDataFields}
													settingsClearances={clearancesData}
													data={data.columns}
													onChange={(data) => onDataUpdate('columns', data)}
												/>
											</TabPane>
										</TabContent>
										<hr />
										<FormGroup check row>
											<Col sm={{ size: 10, offset: 2 }}>
												<Button color="primary" onClick={() => handleFormSave()} disabled={!hasFormChanged || formSaveInProgress}>
													{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Save'}
												</Button>
											</Col>
										</FormGroup>
									</>
								)}
							</CardBody>
						</>
					)}
				</Card>
			</Col>
		</Row>
	);
};
