import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { Button, Input, FormGroup, Row, Col } from 'reactstrap';
import { validateInt } from '../../../../../../../../../../validator';
import { FieldTypeValue } from './FieldTypeValue';
import { FieldTypeDataField } from './FieldTypeDataField';
import { FieldTypeClearance } from './FieldTypeClearance';
import { SettingsNotificationsListEntryRecipientCondition } from 'api/settings/notifications';
import { SettingsDataField } from 'api/settings/general';
import { SettingsClearancesType } from 'api/settings/clearances';
import { LogicOperator } from '@mouseware/lib-core';

interface Props {
	recipient: 'user' | 'any-member' | 'administrator';
	conditionsData: Array<SettingsNotificationsListEntryRecipientCondition>;
	dynamicDataFields: Array<SettingsDataField>;
	clearanceFields: Array<SettingsClearancesType>;
	onSave: (newValue: any) => void;
	handleCanSaveChange: (newValue: any) => void;
}

export const ConditionsSectionAddModify = (props: Props) => {
	const { recipient, conditionsData, dynamicDataFields, clearanceFields, onSave, handleCanSaveChange } = props;

	const [isEditMode, setIsEditMode] = useState(false);
	const [data, setData] = useState<SettingsNotificationsListEntryRecipientCondition>();
	const [canSave, setCanSave] = useState(false);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);

	/**
	 * This function will check to make sure that we are good to submit the form anytime that data is changed
	 */
	useEffect(() => {
		let canSave = true;

		if (data == null) {
			setCanSave(false);
			return;
		}

		if (data.field_type == null || (data as any).field_type === '') {
			canSave = false;
		}

		if (data.field == null || data.field === '') {
			canSave = false;
		}

		if (data.field === 'document-expiration') {
			if (data.logicOperator !== 'equal-to' || data.value_type !== 'value' || !validateInt(data.value) || data.value < 0) {
				canSave = false;
			}
		} else {
			if (data.logicOperator == null || (data as any).logicOperator === '') {
				canSave = false;
			}

			if (data.value_type == null || (data as any).value_type === '') {
				canSave = false;
			}

			if (data.value == null || data.value === '') {
				canSave = false;
			}
		}

		setCanSave(canSave);
	}, [data]);

	/**
	 * This function will check for a change on recipient
	 */
	useEffect(() => {
		if (recipient === 'user') {
			if (conditionsData != null && conditionsData.length > 0) {
				const defaultField = dynamicDataFields.filter((field) => !conditionsData.map((condition) => condition.field).includes(field.uid))[0];
				setData({ field_type: 'datafield', field: defaultField.uid, logicOperator: LogicOperator.EQUAL_TO, value_type: 'value', value: '' });
			} else {
				setData({ field_type: 'value', field: 'document-expiration', logicOperator: LogicOperator.EQUAL_TO, value_type: 'value', value: '' });
			}
		} else if (recipient === 'any-member') {
			if (dynamicDataFields != null && dynamicDataFields.length > 0) {
				const defaultField = dynamicDataFields.filter((field) => !conditionsData.map((condition) => condition.field).includes(field.uid))[0];
				setData({ field_type: 'datafield', field: defaultField.uid, logicOperator: LogicOperator.EQUAL_TO, value_type: 'user', value: '' });
			} else if (clearanceFields != null && clearanceFields.length > 0) {
				const defaultField = clearanceFields.filter((field) => !conditionsData.map((condition) => condition.field).includes(field.uid))[0];
				setData({ field_type: 'clearance', field: 'clearance', logicOperator: LogicOperator.EQUAL_TO, value_type: 'value', value: defaultField.uid });
			} else {
				setData({ field_type: 'value', field: '', logicOperator: LogicOperator.EQUAL_TO, value_type: 'user', value: '' });
			}
		} else if (recipient === 'administrator') {
			setData({ field_type: 'value', field: '', logicOperator: LogicOperator.EQUAL_TO, value_type: 'user', value: '' });
		} else {
			Sentry.captureMessage(`Recipient not of known type "${recipient}"`);
		}
	}, [recipient, conditionsData != null ? conditionsData.length : null]);

	/**
	 * This function will handle the toggle of the add mode
	 */
	const handleAddModeToggle = () => {
		handleCanSaveChange(isEditMode);
		setIsEditMode(!isEditMode);
	};

	/**
	 * This function will update the value in the React state
	 * @param {string} fieldName
	 * @param {*} newValue
	 */
	const handleInputFieldChange = async (fieldName: keyof SettingsNotificationsListEntryRecipientCondition, newValue: any) => {
		let newData = { ...data };

		if (fieldName === 'field_type') {
			switch (newValue) {
				case 'datafield':
					if (dynamicDataFields != null && dynamicDataFields.length > 0) {
						if (conditionsData != null && conditionsData.length > 0) {
							const defaultField = dynamicDataFields.filter(
								(field) => !conditionsData.map((condition) => condition.field).includes(field.uid)
							)[0];
							newData['field'] = defaultField.uid;
						} else {
							newData['field'] = dynamicDataFields[0].uid;
						}
					} else {
						(newData as any)['field'] = null;
					}
					break;
				case 'clearance':
					if (clearanceFields != null && clearanceFields.length > 0) {
						if (conditionsData != null && conditionsData.length > 0) {
							const defaultField = clearanceFields.filter((field) => !conditionsData.map((condition) => condition.field).includes(field.uid))[0];
							newData['field'] = 'clearance';
							newData['value'] = defaultField.uid;
						} else {
							newData['field'] = 'clearance';
							newData['value'] = clearanceFields[0].uid;
						}
					} else {
						newData['field'] = 'clearance';
						newData['value'] = null;
					}
					break;
				case 'value':
					newData['field'] = 'document-expiration';
					break;
			}
		}

		newData[fieldName] = newValue;

		setData(newData as any);
	};

	const handleOnSaveClick = () => {
		setFormSaveInProgress(true);
		onSave(data);
		setData(undefined);
		setIsEditMode(false);
		setFormSaveInProgress(false);
	};

	const handleOnCancelClick = () => {
		setData(undefined);
		handleCanSaveChange(true);
		setIsEditMode(false);
	};

	if (data == null) {
		return <></>;
	}

	return (
		<>
			{isEditMode === true ? (
				<>
					<Row>
						<Col md={2}>
							<FormGroup>
								<Input
									type="select"
									name={`field_type`}
									id={`field_type`}
									onChange={(e) => {
										handleInputFieldChange('field_type', e.target.value);
									}}
									value={data != null && data.field_type != null ? data.field_type : ''}
								>
									<option
										value="value"
										disabled={
											conditionsData != null &&
											conditionsData.filter(
												(condition) => condition.field_type === 'value' && condition.field === 'document-expiration'
											)[0] != null
										}
									>
										Value
									</option>
									<option value="datafield">Field</option>
									<option value="clearance">Clearance</option>
								</Input>
							</FormGroup>
						</Col>
						{data.field_type === 'value' ? (
							<FieldTypeValue data={data} onChange={handleInputFieldChange} />
						) : data.field_type === 'datafield' ? (
							<FieldTypeDataField
								recipient={recipient}
								data={data}
								conditionsData={conditionsData}
								dynamicDataFields={dynamicDataFields}
								onChange={handleInputFieldChange}
							/>
						) : data.field_type === 'clearance' ? (
							<FieldTypeClearance
								data={data}
								conditionsData={conditionsData}
								clearanceFields={clearanceFields}
								onChange={handleInputFieldChange}
							/>
						) : (
							<></>
						)}
						<Col md={2} className="d-flex justify-content-end">
							<FormGroup>
								<Button
									color="primary"
									size="sm"
									onClick={() => {
										handleOnSaveClick();
									}}
									disabled={!canSave || formSaveInProgress}
								>
									Add
								</Button>
								<Button color="link" size="sm" style={{ paddingLeft: '5px', paddingRight: '5px' }} onClick={() => handleOnCancelClick()}>
									<i className="fa fa-times" />
								</Button>
							</FormGroup>
						</Col>
					</Row>
				</>
			) : (
				<Button color="link" size="sm" onClick={() => handleAddModeToggle()}>
					<i className="fa fa-plus" /> Add Condition
				</Button>
			)}
		</>
	);
};
