import React, { useContext, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/browser';

import { Button, UncontrolledTooltip } from 'reactstrap';

import { report } from '../../../api';
import UserContext from '../../UserContext';
import { ReportDB, ReportRunDB, report_output_format, report_type } from 'api/reports';
import { validateReportSettings } from '../helpers/validateReportSettings';
import { FaExclamationTriangle, FaPlay, FaSpinner } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { ModalOverlay } from 'components/Modal/Modal';

interface Props {
	data: ReportDB;
	onEdit: (docID: string) => void;
	onCopy: (docID: string) => void;
	onDelete: (docID: string) => void;
}

export const ReportTableRecord = (props: Props) => {
	const { data, onEdit, onDelete, onCopy } = props;
	const { jwt } = useContext(UserContext);

	const pollingCount = useRef(0);

	const [showRunModal, setShowRunModal] = useState<boolean>(false);
	const [reportRunOptions, setReportRunOptions] = useState<{ download: boolean; sendNotification: boolean }>({ download: false, sendNotification: false });

	const [isRunning, setIsRunning] = useState<boolean>(false);
	const [hasConfigurationError, setHasConfigurationError] = useState<Array<{ field: keyof ReportDB; message: string }>>([]);
	const [hasRunError, setHasRunError] = useState<boolean>(false);

	useEffect(() => {
		setHasConfigurationError(validateReportSettings(data));

		return () => {
			pollingCount.current = 100000;
		};
	}, []);

	const pollForReportStatus = async (runID: string) => {
		let reportRunCompleted = false;
		let runStatus: ReportRunDB | null = null;

		while (reportRunCompleted === false && pollingCount.current < 15) {
			console.log('Polling', pollingCount.current, 2000 + pollingCount.current * 5000);

			// Wait for polling
			await new Promise((resolve) => setTimeout(resolve, 2000 + pollingCount.current * 5000)); // 5 seconds + poll count * 7 seconds

			// Check the status
			try {
				const response = await report.runStatus(jwt, data.docID, runID);

				if (response.status === 'completed') {
					console.log('Status is completed');
					runStatus = response;

					reportRunCompleted = true;
				} else {
					console.log('Status is', response.status);
				}
			} catch (error) {
				console.error(error);
				Sentry.captureException(error);
				setHasRunError(true);
				setIsRunning(false);
			}

			pollingCount.current++;
		}

		// Get the download
		if (reportRunCompleted === true) {
			if (runStatus != null && runStatus.administrator_download === true) {
				try {
					const response = await report.runDownload(jwt, data.docID, runID);

					if (response.type === 'application/json') {
						return;
					}

					// Create blob link to download
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(response);
					if (runStatus.report_configuration.outputFormat === 'excel') {
						link.setAttribute('download', `${runStatus.report_configuration.title}.xlsx`);
					} else if (runStatus.report_configuration.outputFormat === 'pdf') {
						link.setAttribute('download', `${runStatus.report_configuration.title}.pdf`);
					}

					// Append to html page
					document.body.appendChild(link);

					// Force download
					link.click();

					// Clean up and remove the link
					link.parentNode!.removeChild(link);
				} catch (error) {
					console.error(error);
					Sentry.captureException(error);
					setHasRunError(true);
					setIsRunning(false);
				}
			}

			toast.success('Report run successful');
			setIsRunning(false);
		}

		pollingCount.current = 0;
		reportRunCompleted = false;
	};

	const handleRecordRunClick = async () => {
		setHasRunError(false);
		setShowRunModal(true);
	};

	const handleRecordRunConfirmClick = async () => {
		setIsRunning(true);

		try {
			const runID = await report.run(jwt, data.docID, {
				download_report: reportRunOptions.download,
				send_notification: reportRunOptions.sendNotification,
			});
			pollForReportStatus(runID);
		} catch (error: any) {
			toast.error(error.message ? error.message : 'Unknown error');
			Sentry.captureException(error);
			setHasRunError(true);
			setIsRunning(false);
		} finally {
			setReportRunOptions({ download: false, sendNotification: false });
			setShowRunModal(false);
		}
	};

	return (
		<tr key={data.docID}>
			<td>
				{hasConfigurationError.length > 0 && (
					<>
						<span id={`report-${data.docID}-error`}>
							<FaExclamationTriangle />
						</span>
						<UncontrolledTooltip delay={0} target={`report-${data.docID}-error`}>
							<strong>Config error:</strong>
							<br />
							{hasConfigurationError.map((error) => {
								return (
									<div key={error.message}>
										{error.message}
										<br />
									</div>
								);
							})}
						</UncontrolledTooltip>
					</>
				)}
			</td>
			<td>{data.title}</td>
			<td>{data.type === report_type.AD_HOC ? 'ad-hoc' : data.type === report_type.RECURRING ? 'Recurring' : ''}</td>
			<td>{data.outputFormat === report_output_format.PDF ? 'PDF' : data.outputFormat === report_output_format.EXCEL ? 'Excel' : ''}</td>
			<td>{data.last_run ? data.last_run : 'N/A'}</td>
			<td className="text-right">
				<Button
					className="btn-icon record-run"
					color="success"
					id={`report-${data.docID}-run`}
					size="sm"
					type="button"
					disabled={hasConfigurationError.length > 0 || isRunning}
					onClick={handleRecordRunClick}
				>
					{isRunning ? (
						<FaSpinner size={12} className="fa-spin" />
					) : hasRunError === true ? (
						<FaExclamationTriangle size={12} />
					) : (
						<FaPlay size={12} />
					)}
				</Button>{' '}
				<UncontrolledTooltip delay={0} target={`report-${data.docID}-run`}>
					Run
				</UncontrolledTooltip>
				<Button
					className="btn-icon"
					color="secondary"
					id={`report-${data.docID}-copy`}
					size="sm"
					type="button"
					disabled={isRunning}
					onClick={() => onCopy(data.docID)}
				>
					<i className="fa fa-copy" />
				</Button>{' '}
				<UncontrolledTooltip delay={0} target={`report-${data.docID}-copy`}>
					Copy
				</UncontrolledTooltip>
				<Button
					className="btn-icon"
					color="info"
					id={`report-${data.docID}-edit`}
					size="sm"
					type="button"
					disabled={isRunning}
					onClick={() => onEdit(data.docID)}
				>
					<i className="fa fa-edit" />
				</Button>{' '}
				<UncontrolledTooltip delay={0} target={`report-${data.docID}-edit`}>
					Edit
				</UncontrolledTooltip>
				<Button
					className="btn-icon"
					color="danger"
					id={`report-${data.docID}-delete`}
					size="sm"
					type="button"
					disabled={isRunning}
					onClick={() => onDelete(data.docID)}
				>
					<i className="fa fa-times" />
				</Button>{' '}
				<UncontrolledTooltip delay={0} target={`report-${data.docID}-delete`}>
					Delete
				</UncontrolledTooltip>
				<ModalOverlay
					show={showRunModal}
					icon={FaPlay}
					title="Report Run"
					primaryActionDisabled={reportRunOptions.download === false && reportRunOptions.sendNotification === false}
					primaryActionCallback={handleRecordRunConfirmClick}
					primaryActionText="Run"
					secondaryActionText="Cancel"
					secondaryActionCallback={() => {
						setReportRunOptions({ download: false, sendNotification: false });
						setShowRunModal(false);
					}}
				>
					<Button
						type="button"
						color={reportRunOptions.download === true ? 'success' : 'secondary'}
						onClick={() => setReportRunOptions((prevData) => ({ ...prevData, download: !prevData.download }))}
					>
						Download
					</Button>
					<Button
						type="button"
						color={reportRunOptions.sendNotification === true ? 'success' : 'secondary'}
						onClick={() => setReportRunOptions((prevData) => ({ ...prevData, sendNotification: !prevData.sendNotification }))}
					>
						Send Notification
					</Button>
				</ModalOverlay>
			</td>
		</tr>
	);
};
