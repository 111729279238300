import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Col, Row, Table, UncontrolledTooltip } from 'reactstrap';
import * as Sentry from '@sentry/browser';

import { member } from '../../../../../api';

import UserContext from '../../../../UserContext';
import toast from 'react-hot-toast';
import { MemberImportDB } from 'api/members/import';

interface Props {}

export const TemplateHistory = (props: Props) => {
	const {} = props;
	const { templateID } = useParams<{ templateID: string }>();
	const { jwt } = useContext(UserContext);

	const [data, setData] = useState<Array<MemberImportDB>>([]);

	useEffect(() => {
		const getContents = async () => {
			try {
				const response = await member.import.getTemplateRuns(jwt, templateID);
				setData(response);
			} catch (err) {
				// TODO: Handle better
				console.log('error');
				console.log(err);
				Sentry.captureException(err);
			}
		};

		getContents();
	}, []);

	const handleRunFileDownloadClick = async (docID: string) => {
		try {
			const blob = await member.import.getImportRunFile(jwt, docID);
			// Create blob link to download
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${docID}.xlsx`);

			// Append to html page
			document.body.appendChild(link);

			// Force download
			link.click();

			// Clean up and remove the link
			link.parentNode!.removeChild(link);
		} catch (error) {
			console.error(error);
			toast.error('Failed to download file');
			Sentry.captureException(error);
		}
	};

	return (
		<Row>
			<Col sm={12}>
				{data != null && data.length > 0 ? (
					<Table responsive style={{ marginBottom: 0 }}>
						<thead className="text-primary">
							<tr>
								<th className="text-left" style={{ width: '15%' }}>
									Created
								</th>
								<th className="text-left" style={{ width: '70%' }}>
									State
								</th>
								<th className="text-right" style={{ width: '15%' }}>
									Actions
								</th>
							</tr>
						</thead>
						<tbody>
							{data.map((row, key) => {
								const { docID } = row;

								const createdDate = new Date(row.created_date);

								let state = 'Unknown';
								switch (row.processing_state) {
									case 'created':
										state = 'Created';
										break;
									case 'processing-file':
										state = 'Processing File';
										break;
									case 'file-processed':
										state = 'File Processed';
										break;
									case 'generating-backup':
										state = 'Generating Backup';
										break;
									case 'backup-generated':
										state = 'Backup Generated';
										break;
									case 'processing-import':
										state = 'Processing Import';
										break;
									case 'executed':
										state = 'Executed';
										break;
									case 'failed':
										state = 'Failed';
										break;
								}

								return (
									<tr key={`member-import-templates-list-templates-section-${docID}`}>
										<td>
											{(1 + createdDate.getUTCMonth()).toString().padStart(2, '0')}/{createdDate.getUTCDate().toString().padStart(2, '0')}
											/{createdDate.getUTCFullYear()}
										</td>
										<td>{state}</td>
										<td className="text-right">
											<Button
												className="btn-icon"
												color="secondary"
												id={`member-import-templates-list-runs-${key}-download`}
												size="sm"
												type="button"
												onClick={() => handleRunFileDownloadClick(docID)}
											>
												<i className="fa fa-download" />
											</Button>
											<UncontrolledTooltip delay={0} target={`member-import-templates-list-runs-${key}-download`}>
												Download Import File
											</UncontrolledTooltip>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				) : (
					<Alert color="secondary">
						<span>No runs available</span>
					</Alert>
				)}
			</Col>
		</Row>
	);
};
