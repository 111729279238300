import React, { useContext, useEffect, useState } from 'react';

import { Button, Modal, Nav, NavItem, NavLink, TabPane, TabContent, Row, Col, ModalHeader } from 'reactstrap';

import { DetailsTab } from './member-details-overlay/DetailsTab';
import { DocumentsTab } from './member-details-overlay/DocumentsTab';
import { HistoryTab } from './member-details-overlay/HistoryTab';

import { setting } from '../../../api';
import UserContext from '../../UserContext';
import { SettingsDataField } from 'api/settings/general';
import { generatePath, RouteComponentProps, useParams } from 'react-router-dom';

interface Props extends RouteComponentProps {}

export const MemberDetailsOverlay = (props: Props) => {
	const { history, match } = props;
	const { uid: docID } = useParams<{ uid: string }>();

	const { jwt } = useContext(UserContext);

	const [modalOpen, setModalOpen] = useState(true);
	const [activeTab, setActiveTab] = useState('details');
	const [dynamicSettingsDataFields, setDynamicSettingsDataFields] = useState<Array<SettingsDataField>>([]);
	const [closeModalDisabled, setCloseModalDisabled] = useState(false);
	const [data, setData] = useState({});

	useEffect(() => {
		setting.general
			.get(jwt)
			.then((settings) => {
				setDynamicSettingsDataFields(settings.data_fields);
			})
			.catch((e) => {
				// TODO: Handle better
				console.log('error');
				console.log(e);
			});
	}, []);

	const handleCloseClick = () => {
		setModalOpen(false);
	};

	const handleCloseAction = () => {
		history.push({ pathname: match.path.replace('/:uid', '') });
	};

	const handleTabClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabName: 'details' | 'documents' | 'history') => {
		setActiveTab(tabName);

		// We don't want to have the actual <a> perform an action
		e.preventDefault();
	};

	const handleCloseModalState = (state: boolean) => {
		setCloseModalDisabled(state);
	};

	return (
		<Modal
			className="modal-lg member-details-overlay"
			isOpen={modalOpen}
			toggle={() => {
				if (closeModalDisabled === false) {
					handleCloseClick();
				}
			}}
		>
			<ModalHeader toggle={() => handleCloseAction()}>Member Details</ModalHeader>
			<div className="modal-body" style={{ padding: 15 }}>
				<Row>
					<Col lg="3" md="3" sm="3" xs="4">
						<div className="nav-tabs-navigation verical-navs p-0">
							<div className="nav-tabs-wrapper">
								<Nav className="flex-column nav-stacked" role="tablist" tabs>
									<NavItem>
										<NavLink
											data-toggle="tab"
											href="#"
											role="tab"
											className={activeTab === 'details' ? 'active' : ''}
											onClick={(e) => handleTabClick(e, 'details')}
										>
											Details
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											data-toggle="tab"
											href="#"
											role="tab"
											className={activeTab === 'documents' ? 'active' : ''}
											onClick={(e) => handleTabClick(e, 'documents')}
										>
											Documents
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											data-toggle="tab"
											href="#"
											role="tab"
											className={activeTab === 'history' ? 'active' : ''}
											onClick={(e) => handleTabClick(e, 'history')}
										>
											History
										</NavLink>
									</NavItem>
								</Nav>
							</div>
						</div>
					</Col>
					<Col lg="9" md="9" sm="9" xs="8">
						<TabContent activeTab={activeTab}>
							<TabPane tabId="details">
								<DetailsTab
									key={`member-details-overlay-details-tab-${docID}`}
									uid={docID}
									closeModalControl={handleCloseModalState}
									closeModalAction={() => {
										handleCloseClick();
									}}
								/>
							</TabPane>
							<TabPane tabId="documents">
								<DocumentsTab key={`member-details-overlay-documents-tab-${docID}`} uid={docID} closeModalControl={handleCloseModalState} />
							</TabPane>
							<TabPane tabId="history">
								<HistoryTab key={`member-details-overlay-history-tab-${docID}`} uid={docID} />
							</TabPane>
						</TabContent>
					</Col>
				</Row>
			</div>
			<div className="modal-footer justify-content-center">
				<Button
					className="btn-round"
					color="primary"
					data-dismiss="modal"
					type="button"
					onClick={() => {
						handleCloseClick();
					}}
					disabled={closeModalDisabled}
				>
					Close
				</Button>
			</div>
		</Modal>
	);
};
