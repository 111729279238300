import React, { useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import { RouteComponentProps } from 'react-router-dom';

import { Button, Modal, Row, Col, CardTitle, Form, Alert, ModalHeader } from 'reactstrap';

import { TabData } from './member-details-overlay/details-tab/TabData';

// Exceptions
import { ArchivedMemberExistsException } from '../../../api/exceptions/ArchivedMemberExistsException';
import { MemberExistsException } from '../../../api/exceptions/MemberExistsException';
import { MemberNotFoundException } from '../../../api/exceptions/MemberNotFoundException';

import { setting, member } from '../../../api';
import UserContext from '../../UserContext';
import { FaSpinner } from 'react-icons/fa';
import { SettingsDataField } from 'api/settings/general';
import { MemberDB } from 'api/members';

interface Props extends RouteComponentProps {
	uid?: string;
}

export const MemberAddOverlay = (props: Props) => {
	const { jwt } = useContext(UserContext);
	const { history, match } = props;

	const [modalOpen, setModalOpen] = useState(true);
	const [dynamicSettingsDataFields, setDynamicSettingsDataFields] = useState<Array<SettingsDataField>>([]);
	const [primaryFieldUID, setPrimaryFieldUID] = useState<string | null>(null);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);
	const [saveError, setSaveError] = useState<string | null>(null);
	const [data, setData] = useState<MemberDB>();
	const [existingMember, setExistingMember] = useState<{ docID?: string; message?: string }>({});
	const [inputFieldMultipleAddMode, setInputFieldMultipleAddMode] = useState<Array<any>>([]);
	const [canSave, setCanSave] = useState(false);

	useEffect(() => {
		const getContents = async () => {
			try {
				const response = await setting.general.get(jwt);
				setDynamicSettingsDataFields(response.data_fields);
				setPrimaryFieldUID(response.data_fields.filter((field) => field.primary === true)[0].uid);
			} catch (error) {
				// TODO: Handle better
				console.log('error');
				console.log(error);
				Sentry.captureException(error);
			}
		};

		getContents();
	}, []);

	useEffect(() => {
		let canSave = true;

		if (primaryFieldUID != null) {
			if (data == null || data[primaryFieldUID] == null || data[primaryFieldUID] === '') {
				canSave = false;
			}
		}

		if (inputFieldMultipleAddMode.length !== 0) {
			canSave = false;
		}

		if (existingMember.docID != null) {
			canSave = false;
		}

		setCanSave(canSave);
	}, [inputFieldMultipleAddMode, existingMember, data]);

	// const processDebounce = primaryFieldSearchDebounce((val) => handlePrimaryFieldCheck(val), 500);

	function primaryFieldSearchDebounce(func: () => void, timeout = 300) {
		let timer: NodeJS.Timeout;

		return (...args: any) => {
			clearTimeout(timer);

			timer = setTimeout(function (this: any) {
				console.log('Calling callback');
				func.apply(this, args);
			}, timeout);
		};
	}

	async function handlePrimaryFieldCheck(fieldValue: number) {
		try {
			// The logic is backwards... no response means we are good (doesn't exist)
			await member.getStatus(jwt, fieldValue);
			setExistingMember({});
			return false;
		} catch (error) {
			if (error instanceof MemberNotFoundException) {
				console.log('NOT FOUND');
				setExistingMember({});
				return true;
			} else if (error instanceof MemberExistsException) {
				console.log('EXISTS');
				console.log(error.message);
				setExistingMember({ docID: error.message, message: 'Member already exists.' });
				return true;
			} else if (error instanceof ArchivedMemberExistsException) {
				console.log('ARCHIVED');
				setExistingMember({ docID: error.message, message: 'Member is archived.' });
				return true;
			} else {
				setExistingMember({});
			}
		}
	}

	function handleInputFieldChange(fieldName: keyof MemberDB, newValue: any, dataType: any, isPrimaryField?: boolean) {
		let newData = { ...data };
		let val = newValue;

		if (dataType != null) {
			if (dataType === 'int') {
				if (Array.isArray(val)) {
					val = val.map((nonParsedVal) => {
						return parseInt(nonParsedVal, 10);
					});
				} else {
					val = parseInt(val, 10);
				}
			}
		}

		newData[fieldName] = val;

		setData(newData as any);

		if (isPrimaryField === true) {
			primaryFieldSearchDebounce(() => handlePrimaryFieldCheck(val), 500)();
		}
	}

	function handleInputFieldMultipleAddMode(detailsFieldUID: string, state: boolean) {
		let newInputFieldMultipleAddMode = [...inputFieldMultipleAddMode];

		const indexOf = newInputFieldMultipleAddMode.indexOf(detailsFieldUID);
		if (indexOf >= 0) {
			// Already exists
			if (state === false) {
				// Remove from list
				newInputFieldMultipleAddMode.splice(indexOf, 1);
			}
		} else {
			// Doesn't exist
			if (state === true) {
				// Add it to the list
				newInputFieldMultipleAddMode.push(detailsFieldUID);
			}
		}

		setInputFieldMultipleAddMode(newInputFieldMultipleAddMode);
	}

	function handleCloseClick() {
		setModalOpen(false);
	}

	function handleCloseAction() {
		history.push({ pathname: match.path.replace('/add', '') });
	}

	async function handleMemberDetailsDataValidation() {
		if (inputFieldMultipleAddMode.length > 0) {
			return false;
		}

		// TODO: Implement
		return true;
	}

	async function handleAddRecord() {
		// Validate the input first
		const success = await handleMemberDetailsDataValidation();

		if (success) {
			// Input is valid
			return Promise.resolve()
				.then(() => {
					setSaveError(null);
					setFormSaveInProgress(true);
				})
				.then(() => {
					return member
						.create(jwt, data!)
						.then((resp) => {
							handleCloseClick();
						})
						.catch((e) => {
							// TODO: Handle better
							console.log('error');
							console.log(e);
							setSaveError('There was an error saving.');
						});
				})
				.then(() => {
					setFormSaveInProgress(false);
				});
		} else {
			// Input has issues
			console.log('e');
		}
	}

	function handleSaveErrorCloseClick() {
		setSaveError(null);
	}

	return (
		<Modal className="modal-lg" isOpen={modalOpen} toggle={() => handleCloseClick()}>
			<ModalHeader toggle={() => handleCloseClick()}>Add Member</ModalHeader>
			<div className="modal-body" style={{ padding: 15 }}>
				<Row>
					<Col lg="12" md="12" sm="12" xs="12">
						<div className="details-tab">
							<CardTitle tag="h4" style={{ marginTop: 0, marginBottom: '.5rem' }}>
								Enter Information
							</CardTitle>
							{existingMember.docID != null || saveError != null ? (
								<Alert
									color={existingMember.docID == null ? 'danger' : 'primary'}
									toggle={() => {
										handleSaveErrorCloseClick();
									}}
								>
									{existingMember.docID == null ? (
										saveError
									) : (
										<>
											{existingMember.message}{' '}
											<a href={`/administrator/member/${existingMember.docID}`} style={{ color: '#fff' }}>
												View Member
											</a>
										</>
									)}
								</Alert>
							) : (
								<></>
							)}
							<Form className="edit-form">
								{dynamicSettingsDataFields != null ? (
									dynamicSettingsDataFields.map((field) => {
										return (
											<TabData
												key={field.uid}
												field={field}
												value={data != null ? data[field.uid] : null}
												isEditMode={true}
												handleInputFieldChange={handleInputFieldChange}
												handleInputFieldMultipleAddMode={handleInputFieldMultipleAddMode}
											/>
										);
									})
								) : (
									<></>
								)}
							</Form>
						</div>
					</Col>
				</Row>
			</div>
			<div className="modal-footer justify-content-center">
				<Button
					className="btn-round"
					color="primary"
					data-dismiss="modal"
					type="button"
					onClick={() => {
						handleAddRecord();
					}}
					disabled={!canSave || formSaveInProgress}
				>
					{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Add'}
				</Button>
			</div>
		</Modal>
	);
};
