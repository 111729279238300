import React from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';

import { Import } from '../Import';
import { SettingsDataField } from 'api/settings/general';

interface Props extends RouteComponentProps {
	memberImportRootPath: string;
	dynamicSettingsDataFields: Array<SettingsDataField>;
}

export const TemplateUpload = (props: Props) => {
	const { memberImportRootPath, dynamicSettingsDataFields } = props;
	const { templateID } = useParams<{ templateID: string }>();

	return (
		<Import {...props} memberImportRootPath={memberImportRootPath} dynamicSettingsDataFields={dynamicSettingsDataFields} importTemplateID={templateID} />
	);
};
