import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { UserDetails } from './UserDetails';
import { UsersTable } from './partials/UsersTable';

interface Props extends RouteComponentProps {}

export const AdministratorUsers = (props: Props) => {
	const { location, match } = props;

	return (
		<div className="content administrators-users-page">
			<Switch location={location}>
				<Route exact path={match.path + '/add'} render={(props) => <UserDetails {...props} />} />
				<Route exact path={match.path + '/:uid'} render={(props) => <UserDetails {...props} />} />
				<Route exact path={match.path} render={(props) => <UsersTable {...props} />} />
			</Switch>
		</div>
	);
};
