import React, { useState, useContext, useEffect } from 'react';

import { Alert, Button, Card, CardHeader, CardBody, CardFooter, Form, Input, InputGroupText, InputGroup, Container, Col, Row } from 'reactstrap';

import { login } from '../../api';
import UserContext from '../UserContext';

import PasswordValidator from 'password-validator';
import { FaKey, FaSpinner } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {}

export const ResetPassword = (props: Props) => {
	const { history } = props;
	const { isUserAuthenticated } = useContext(UserContext);

	const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
	const [messageState, setMessageState] = useState('info');
	const [message, setMessage] = useState<string | null>(null);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [resetCode, setResetCode] = useState<string | null>(null);
	const [passwordRequirements, setPasswordRequirements] = useState([]);

	const passwordValidatorSchema = new PasswordValidator();
	passwordValidatorSchema
		.is()
		.min(12) // Minimum length 12
		.is()
		.max(100) // Maximum length 100
		.has()
		.uppercase() // Must have uppercase letters
		.has()
		.lowercase() // Must have lowercase letters
		.has()
		.digits(2) // Must have at least 2 digits
		.has()
		.symbols(2) // Must have at least 2 symbols
		.has()
		.not()
		.spaces(); // Should not have spaces

	useEffect(() => {
		if (isUserAuthenticated === true) {
			history.push('/administrator/dashboard');
		} else {
			const search = new URLSearchParams(history.location.search);
			const rc = search.get('c');

			if (rc == null) {
				setMessageState('danger');
				setMessage('You need to provide a code');
			} else {
				setResetCode(rc);
			}
		}
	}, []);

	useEffect(() => {
		const validPasswordRequirements = (passwordValidatorSchema as any).validate(password, { list: true });
		setPasswordRequirements(validPasswordRequirements);
	}, [password]);

	/**
	 * Process the reset password form
	 * @param {*} event the event triggering this function
	 */
	async function handleResetPasswordFormAction(event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();

		try {
			// Check if we have valid input
			// TODO: Implement better checks
			if (password === '' || confirmPassword === '' || resetCode == null) {
				throw new Error('Please enter a new password');
			}

			if (password !== confirmPassword) {
				throw new Error('Passwords do not match');
			}

			if (passwordRequirements.length > 0) {
				throw new Error('Password does not meet minimum requirements');
			}

			// Set the state
			setMessageState('info');
			setMessage(null);
			setFormSubmitInProgress(true);

			// Process the reset request
			try {
				const data = await login.resetPassword(resetCode, password);

				setMessageState('info');
				setMessage('Password reset successful.');
				setPassword('');
				setConfirmPassword('');

				// Redirect user to login
				setTimeout(() => {
					history.push('/administrator/login');
				}, 1500);
			} catch (error: any) {
				setMessageState('danger');

				if (error.message != null) {
					setMessage(error.message);
				} else {
					setMessage('Invalid information provided');
				}
			}

			// Set the state
			setFormSubmitInProgress(false);
		} catch (error: any) {
			setMessageState('danger');
			setMessage(error.message);
			setFormSubmitInProgress(false);
		}
	}

	return (
		<div className="full-page section-image">
			<div className="forgot-password-page">
				<Container>
					<Row>
						<Col className="ml-auto mr-auto" lg="4" md="6">
							<Form action="" className="form" method="" onSubmit={(e) => handleResetPasswordFormAction(e)}>
								<Card className="card-login">
									<CardHeader>
										<CardHeader>
											<h3 className="header text-center">Reset Password</h3>
										</CardHeader>
									</CardHeader>
									<CardBody>
										{message != null && (
											<Alert color={messageState}>
												<span>{message}</span>
											</Alert>
										)}
										<InputGroup>
											<InputGroupText style={{ background: 'none' }}>
												<FaKey color="#66615b" />
											</InputGroupText>
											<Input placeholder="Password" type="password" autoComplete="off" onChange={(e) => setPassword(e.target.value)} />
										</InputGroup>
										<InputGroup>
											<ul className="mb-0" style={{ fontSize: '8pt' }}>
												<li
													style={
														password.length > 0 && (passwordRequirements as any).includes('min')
															? { color: '#f1926e' }
															: password.length > 0
															? { color: '#6bd098' }
															: {}
													}
												>
													Minimum length 12 characters
												</li>
												<li
													style={
														password.length > 0 && (passwordRequirements as any).includes('uppercase')
															? { color: '#f1926e' }
															: password.length > 0
															? { color: '#6bd098' }
															: {}
													}
												>
													At least 1 uppercase character
												</li>
												<li
													style={
														password.length > 0 && (passwordRequirements as any).includes('lowercase')
															? { color: '#f1926e' }
															: password.length > 0
															? { color: '#6bd098' }
															: {}
													}
												>
													At least 1 lowercase character
												</li>
												<li
													style={
														password.length > 0 && (passwordRequirements as any).includes('digits')
															? { color: '#f1926e' }
															: password.length > 0
															? { color: '#6bd098' }
															: {}
													}
												>
													At least 2 digits
												</li>
												<li
													style={
														password.length > 0 && (passwordRequirements as any).includes('symbols')
															? { color: '#f1926e' }
															: password.length > 0
															? { color: '#6bd098' }
															: {}
													}
												>
													At least 2 symbols
												</li>
											</ul>
										</InputGroup>
										<InputGroup>
											<InputGroupText style={{ background: 'none' }}>
												<FaKey color="#66615b" />
											</InputGroupText>
											<Input
												placeholder="Confirm Password"
												type="password"
												autoComplete="off"
												onChange={(e) => setConfirmPassword(e.target.value)}
											/>
										</InputGroup>
									</CardBody>
									<CardFooter>
										<Button
											type="submit"
											block
											className="btn-round mb-3"
											color="warning"
											onClick={(e) => handleResetPasswordFormAction(e)}
											disabled={formSubmitInProgress === true}
										>
											{formSubmitInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Submit'}
										</Button>
										<div style={{ textAlign: 'center' }}>
											<a href="/administrator/login" style={{ color: '#000' }}>
												Login
											</a>
										</div>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</Container>
				<div
					className="full-page-background"
					style={{
						backgroundImage: `url(${process.env.REACT_APP_FRONTEND_BACKGROUND_IMAGE || '/img/bg/default-background.jpg'})`,
					}}
				/>
			</div>
		</div>
	);
};
