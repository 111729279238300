import React, { useState, useEffect, useContext } from 'react';

import { Alert, Button, ButtonGroup, Col, CardTitle, Input, Label, Form, FormGroup, UncontrolledTooltip } from 'reactstrap';

import { TabData } from './details-tab/TabData';

import { setting, member } from '../../../../api';

import UserContext from '../../../UserContext';
import { FaSpinner } from 'react-icons/fa';
import { SettingsDataField } from 'api/settings/general';
import { MemberDB } from 'api/members';
import { MemberGroup } from 'api/settings/clearances';

interface Props {
	uid: string;
	closeModalAction: (state: boolean) => void;
	closeModalControl: (state: boolean) => void;
}

export const DetailsTab = (props: Props) => {
	const { jwt } = useContext(UserContext);
	const { uid, closeModalControl } = props;

	const [dynamicSettingsDataFields, setDynamicSettingsDataFields] = useState<Array<SettingsDataField>>([]);
	const [clearanceMemberGroupSettings, setClearanceMemberGroupSettings] = useState<Array<MemberGroup>>([]);
	const [primaryFieldUID, setPrimaryFieldUID] = useState<string | null>(null);
	const [data, setData] = useState<MemberDB>();
	const [isEditDetailsMode, setIsEditDetailsMode] = useState(false);
	const [isEditDetailsSaving, setIsEditDetailsSaving] = useState(false);
	const [editModeOriginalData, setEditModeOriginalData] = useState<MemberDB>();
	const [inputFieldMultipleAddMode, setInputFieldMultipleAddMode] = useState<Array<string>>([]);
	const [canSave, setCanSave] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		const getContents = async () => {
			try {
				const settingsResp = await setting.general.get(jwt);
				setDynamicSettingsDataFields(settingsResp.data_fields);
				setPrimaryFieldUID(settingsResp.data_fields.filter((field) => field.primary === true)[0].uid);

				const memberResp = await member.get(jwt, uid);

				setData(memberResp);
			} catch (err: any) {
				// TODO: Handle better
				console.log('error');
				console.log(err);
				setErrorMessage(err.message);
			}
		};

		getContents();
	}, []);

	useEffect(() => {
		let canSave = true;

		if (primaryFieldUID != null) {
			if (data![primaryFieldUID] == null || data![primaryFieldUID] === '') {
				canSave = false;
			}
		}

		if (inputFieldMultipleAddMode.length !== 0) {
			canSave = false;
		}

		setCanSave(canSave);
	}, [isEditDetailsMode, inputFieldMultipleAddMode, data]);

	/**
	 * This function will update the value in the React state
	 * @param {string} fieldName
	 * @param {*} newValue
	 * @param {string} dataType
	 * @param {boolean} isPrimaryField
	 */
	function handleInputFieldChange(fieldName: string, newValue: any, dataType?: string, isPrimaryField?: boolean) {
		let newData = { ...data! };
		let val = newValue;

		if (newValue != null && newValue.length > 0 && dataType != null) {
			if (dataType === 'int') {
				if (Array.isArray(val)) {
					val = val.map((nonParsedVal) => {
						return parseInt(nonParsedVal, 10);
					});
				} else {
					val = parseInt(val, 10);
				}
			}
		}

		newData[fieldName] = val;

		setData(newData);

		// TODO: Implement primary field check
	}

	/**
	 * This function will keep track of the multiple fields that are in the add mode
	 * @param {string} detailsFieldUID
	 * @param {boolean} state
	 */
	function handleInputFieldMultipleAddMode(detailsFieldUID: string, state: boolean) {
		let newInputFieldMultipleAddMode = [...inputFieldMultipleAddMode];

		const indexOf = newInputFieldMultipleAddMode.indexOf(detailsFieldUID);
		if (indexOf >= 0) {
			// Already exists
			if (state === false) {
				// Remove from list
				newInputFieldMultipleAddMode.splice(indexOf, 1);
			}
		} else {
			// Doesn't exist
			if (state === true) {
				// Add it to the list
				newInputFieldMultipleAddMode.push(detailsFieldUID);
			}
		}

		setInputFieldMultipleAddMode(newInputFieldMultipleAddMode);
	}

	function toggleEditModeClick() {
		return Promise.resolve()
			.then(() => {
				closeModalControl(!isEditDetailsMode);
			})
			.then(() => {
				if (isEditDetailsMode === true) {
					// We are currently editing
					const origData = editModeOriginalData;
					setIsEditDetailsMode(false);
					setData(origData);
					setEditModeOriginalData(undefined);
				} else {
					// We are not editing
					setIsEditDetailsMode(true);
					setEditModeOriginalData(JSON.parse(JSON.stringify(data)));
				}
			});
	}

	/**
	 * This function handles updating a record
	 */
	function handleEditButtonSaveClick() {
		return Promise.resolve()
			.then(() => {
				setErrorMessage(null);
				setIsEditDetailsSaving(true);
			})
			.then(() => {
				// TODO: Handle use case where BSA-ID (uid) changes
				return member
					.update(jwt, uid, data!)
					.then(() => {
						setIsEditDetailsMode(false);
						setEditModeOriginalData(undefined);
						setIsEditDetailsSaving(false);
					})
					.then(() => {
						closeModalControl(false);
					})
					.catch((e) => {
						console.log(e);
						setErrorMessage(e.message);
						setIsEditDetailsSaving(false);
					});
			});
	}

	/**
	 * Toggles the archive button click in the overlay
	 */
	function handleArchiveButtonClick() {
		if (data!.archived == null) {
			return member
				.archive(jwt, uid)
				.then(() => {
					// Update the data
					const newData = { ...data! };
					newData.archived = new Date();
					setData(newData);

					// Set the state of everything
					setIsEditDetailsMode(false);
					setIsEditDetailsSaving(false);
					setEditModeOriginalData(undefined);
				})
				.catch((e) => {
					// TODO: Handle better
					console.log('error');
					console.log(e.message);
				});
		} else {
			return member
				.restore(jwt, uid)
				.then(() => {
					// Update the data
					const newData = { ...data! };
					newData.archived = null;
					setData(newData);
				})
				.catch((e) => {
					// TODO: Handle better
					console.log('error');
					console.log(e);
				});
		}
	}

	const memberClearanceGroupName = clearanceMemberGroupSettings.filter((memberGroup) => memberGroup.uid === data!['member-group'])[0];

	return (
		<div className="details-tab">
			{!isEditDetailsMode ? (
				<>
					<ButtonGroup className="pull-right">
						<Button
							id="member-details-overlay-details-tab-edit-mode-button"
							color="info"
							size="sm"
							className="btn-icon edit-mode-button"
							disabled={data != null && data.archived != null}
							onClick={() => toggleEditModeClick()}
						>
							<i className="fa fa-pencil" />
						</Button>
						<Button
							id="member-details-overlay-details-tab-archive-button"
							color="secondary"
							size="sm"
							className="btn-icon archive-button"
							onClick={() => handleArchiveButtonClick()}
						>
							<i className="fa fa-archive" />
						</Button>
					</ButtonGroup>

					<UncontrolledTooltip delay={0} target={'member-details-overlay-details-tab-edit-mode-button'}>
						Edit Member
					</UncontrolledTooltip>
					<UncontrolledTooltip delay={0} target={'member-details-overlay-details-tab-archive-button'}>
						{data != null && data.archived == null ? 'Archive' : 'Unarchive'} Member
					</UncontrolledTooltip>
				</>
			) : (
				<>
					<Button color="link" size="sm" className="pull-right edit-mode-button" onClick={() => toggleEditModeClick()} disabled={isEditDetailsSaving}>
						Cancel
					</Button>
					<Button
						color="info"
						size="sm"
						className="pull-right edit-mode-button"
						onClick={() => handleEditButtonSaveClick()}
						disabled={!canSave || isEditDetailsSaving}
					>
						{!isEditDetailsSaving ? 'Save' : <FaSpinner size={12} className="fa-spin" />}
					</Button>
				</>
			)}
			<CardTitle tag="h4" style={{ marginTop: 0, marginBottom: '.5rem' }}>
				{!isEditDetailsMode ? `Details` : `Edit`}
			</CardTitle>
			{errorMessage != null ? <Alert color="danger">{errorMessage}</Alert> : <></>}
			{data != null && data.archived != null ? <Alert color={'secondary'}>Member is Archived</Alert> : <></>}
			<Form className="edit-form">
				{dynamicSettingsDataFields != null ? (
					dynamicSettingsDataFields.map((field) => {
						return (
							<TabData
								key={field.uid}
								field={field}
								value={data != null ? data[field.uid] : null}
								isEditMode={isEditDetailsMode}
								handleInputFieldChange={handleInputFieldChange}
								handleInputFieldMultipleAddMode={handleInputFieldMultipleAddMode}
							/>
						);
					})
				) : (
					<></>
				)}
				<FormGroup row key={`member-details-overlay-details-tab-data-member-group`}>
					<Label for={'memberGroup'} sm={3}>
						<strong>Member Group</strong>
					</Label>
					<Col sm={9}>
						{!isEditDetailsMode ? (
							memberClearanceGroupName != null ? (
								memberClearanceGroupName.name
							) : (
								'Unknown'
							)
						) : (
							<Input
								type={'select'}
								name={'memberGroup'}
								id={'memberGroup'}
								placeholder={'Clearance Member Group'}
								onChange={(e) => {
									handleInputFieldChange('member-group', e.target.value);
								}}
								defaultValue={data!['member-group']}
							>
								<option value={null as any}>- Select Member Group -</option>
								{clearanceMemberGroupSettings.map((memberGroup) => {
									return (
										<option key={memberGroup.uid} value={memberGroup.uid}>
											{memberGroup.name}
										</option>
									);
								})}
							</Input>
						)}
					</Col>
				</FormGroup>
			</Form>
		</div>
	);
};
