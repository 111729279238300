import React, { useState, useEffect, useContext } from 'react';
import { NavLink as RouterNavLink, RouteComponentProps } from 'react-router-dom';

import { Button, Table, UncontrolledTooltip } from 'reactstrap';
import UserContext from '../../../UserContext';

import { frontend } from '../../../../api';
import { UnauthorizedException } from '../../../../api/exceptions/UnauthorizedException';

interface Props extends RouteComponentProps {}

export const DocumentList = (props: Props) => {
	const { user } = useContext(UserContext);
	const { match, history } = props;
	const [isLoaded, setIsLoaded] = useState(false);
	const [data, setData] = useState<Array<{ uid: string; name: string; readonly: boolean }>>([]);

	/**
	 * This function will retrieve the user clearance data from the backend
	 */
	useEffect(() => {
		const getContents = async () => {
			try {
				const response = await frontend.getProfileClearances(user!.token!);
				setData(response);
				setIsLoaded(true);
			} catch (err) {
				if (err instanceof UnauthorizedException) {
					// Redirect to login
					history.push('/app/login');
				} else {
					// TODO: Handle better
					console.log('error');
					console.log(err);
				}
			}
		};

		getContents();
	}, []);

	return (
		<div className="clearances-tab-document-list">
			{!isLoaded ? (
				<>Loading...</>
			) : (
				<>
					<Table responsive>
						<thead className="text-primary">
							<tr>
								<th>Name</th>
								<th className="text-center">Certification Date</th>
								<th className="text-center">Approved</th>
								<th className="text-right"></th>
							</tr>
						</thead>
						<tbody>
							{data != null && data.length > 0 ? (
								data.map((doc: any) => {
									return (
										<tr key={`member-dashboard-clearances-tab-document-list-field-${doc.docID}`}>
											<td>{doc.name}</td>
											<td className="text-center">{doc.certification_date}</td>
											<td className="text-center">
												{doc.approved != null && doc.approved === true ? <i className="fa fa-check" /> : 'Pending'}
											</td>
											<td className="text-right">
												<Button
													className="btn-icon"
													color="info"
													id={`member-dashboard-clearances-tab-document-list-field-${doc.docID}-tooltip`}
													size="sm"
													type="button"
													tag={RouterNavLink}
													to={`${match.path}/download/${doc.docID}`}
													exact
													disabled={doc.readonly === true}
												>
													<i className="fa fa-download" />
												</Button>
												<UncontrolledTooltip
													delay={0}
													target={`member-dashboard-clearances-tab-document-list-field-${doc.docID}-tooltip`}
												>
													Download document
												</UncontrolledTooltip>
											</td>
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan={5}>No documents found.</td>
								</tr>
							)}
						</tbody>
					</Table>
					<Button color="secondary" size="sm" tag={RouterNavLink} to={`${match.path}/upload`} exact>
						Upload New Document
					</Button>
				</>
			)}
		</div>
	);
};
