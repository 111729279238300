import React, { useState, useEffect, useContext } from 'react';

import { Button, Modal, Form, Input, FormGroup, Label, ModalHeader } from 'reactstrap';
import { DataFieldsDropdownItems } from './DataFieldsDropdownItems';

import { setting } from '../../../../api';

import UserContext from '../../../UserContext';
import { FaTimes } from 'react-icons/fa';
import { SettingsDataField, settings_data_field_type } from 'api/settings/general';

interface Props {
	show: boolean;
	editRecord: SettingsDataField;
	onSave: (inputData: SettingsDataField) => void;
	onClose: () => void;
}

export const DataFieldRecordOverlay = (props: Props) => {
	const { jwt } = useContext(UserContext);
	const { editRecord, onSave, show, onClose } = props;
	const [data, setData] = useState<SettingsDataField>(
		editRecord != null
			? editRecord
			: {
					uid: '',
					name: '',
					description: '',
					primary: false,
					attributes: { type: settings_data_field_type.STRING, displayOnSummaryPage: false, displayOnUserDashboard: false, multiple: false },
			  }
	);

	const [dynamicSettingsDataFields, setDynamicSettingsDataFields] = useState<Array<SettingsDataField>>([]);
	const [canSave, setCanSave] = useState(false);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);

	useEffect(() => {
		setting.general
			.get(jwt)
			.then((settings) => {
				setDynamicSettingsDataFields(settings.data_fields);
			})
			.catch((e) => {
				// TODO: Handle better
				console.log('error');
				console.log(e);
			});

		return () => {
			setFormSaveInProgress(false);
			setData({
				uid: '',
				name: '',
				description: '',
				primary: false,
				attributes: { type: settings_data_field_type.STRING, displayOnSummaryPage: false, displayOnUserDashboard: false, multiple: false },
			});
		};
	}, []);

	useEffect(() => {
		let canSave = true;

		if (data.name == null || data.name === '') {
			canSave = false;
		}

		if (data.description == null || data.description === '') {
			canSave = false;
		}

		if (data.attributes == null || data.attributes.type === null) {
			canSave = false;
		}

		if (data.attributes.type === 'dropdown') {
			if (data.attributes.options == null) {
				canSave = false;
			}
		}

		setCanSave(canSave);
	}, [data]);

	const handleInputFieldChange = async (fieldName: keyof SettingsDataField, newValue: any) => {
		let newData = { ...data };

		(newData as any)[fieldName] = newValue;

		setData(newData);
	};

	const handleOnSaveClick = () => {
		if (data.uid == null) {
			data.uid = data.name.replace(/\s+/g, '-').toLowerCase();
		}

		return Promise.resolve()

			.then(() => {
				setFormSaveInProgress(true);
			})
			.then(() => {
				onSave(data);
			});
	};

	return (
		<Modal isOpen={show} toggle={() => onClose()}>
			<ModalHeader toggle={() => onClose()}>Field Details</ModalHeader>
			<div className="modal-body">
				<Form>
					<FormGroup>
						<Label for="fieldName">Name</Label>
						<Input
							type="text"
							name="fieldName"
							id="fieldName"
							placeholder="Enter Field Name"
							onChange={(e) => {
								handleInputFieldChange('name', e.target.value);
							}}
							value={data.name != null ? data.name : ''}
						/>
					</FormGroup>
					<FormGroup>
						<Label for="fieldDescription">Description</Label>
						<Input
							type="textarea"
							name="fieldDescription"
							id="fieldDescription"
							placeholder="Enter Field Description"
							onChange={(e) => {
								handleInputFieldChange('description', e.target.value);
							}}
							value={data.description != null ? data.description : ''}
						/>
					</FormGroup>
					<FormGroup>
						<Label for="fieldType">Type</Label>
						<Input
							type="select"
							name="fieldType"
							id="fieldType"
							onChange={(e) => handleInputFieldChange('attributes', e.target.value)}
							value={data.attributes.type != null ? data.attributes.type : ''}
						>
							<option value="string">String</option>
							<option value="int">Integer</option>
							<option value="date">Date</option>
							{/* <option value="dropdown">Dropdown</option> */}
						</Input>
					</FormGroup>
					{data.attributes.type != null && data.attributes.type === 'dropdown' ? (
						<DataFieldsDropdownItems
							onFieldsUpdate={(newData) => handleInputFieldChange('attributes', newData)}
							editRecord={data.attributes.options![0]}
						/>
					) : (
						<></>
					)}
				</Form>
			</div>
			<div className="modal-footer justify-content-center">
				<Button
					className="btn-round"
					color="primary"
					type="button"
					onClick={() => {
						handleOnSaveClick();
					}}
					disabled={!canSave || formSaveInProgress}
				>
					{data.uid == null ? 'Add' : 'Save'}
				</Button>
				<Button
					className="btn-round"
					color="secondary"
					data-dismiss="modal"
					type="button"
					onClick={() => {
						onClose();
					}}
				>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};
