import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { ReportDetails } from './ReportDetails/ReportDetails';
import { ReportTable } from './ReportTable/ReportTable';

import './Reports.scss';

interface Props extends RouteComponentProps {}

export const Reports = (props: Props) => {
	const { location, match } = props;

	return (
		<div className="content report-page">
			<Switch location={location}>
				<Route exact path={match.path + '/add'} render={(props) => <ReportDetails {...props} />} />
				<Route exact path={match.path + '/:uid/:tabID?'} render={(props) => <ReportDetails {...props} />} />
				<Route exact path={match.path} render={(props) => <ReportTable {...props} />} />
			</Switch>
		</div>
	);
};
