import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface Props {
	width: number;
	height: number;
	children: React.ReactNode;
}

export const DocumentPortal = (props: Props) => {
	const containerEl = useRef(document.createElement('div'));
	const externalWindow = useRef<Window>();

	useEffect(() => {
		const init = async () => {
			const { width, height } = props;

			externalWindow.current = window.open('', '', `width=${width},height=${height},left=200,top=200`) as Window;

			const style = document.createElement('style');
			style.type = 'text/css';
			style.appendChild(document.createTextNode('body {margin: 0px;}'));

			if (externalWindow.current == null) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
			}

			externalWindow.current.document.head.appendChild(style);
			externalWindow.current.document.body.appendChild(containerEl.current);
		};

		init();

		return () => {
			if (externalWindow.current != null) {
				externalWindow.current.close();
			}
		};
	}, []);

	return ReactDOM.createPortal(props.children, containerEl.current);
};
