import React, { useContext, useEffect, useState } from 'react';

import { FullPageLoading } from '../../FullPageLoading';

import { member } from '../../../api';

import UserContext from '../../UserContext';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {}

export const MemberDownloadOverlay = (props: Props) => {
	const { match } = props;
	const { jwt } = useContext(UserContext);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		Promise.resolve()
			.then(() => {
				setLoading(true);
			})
			.then(() => {
				return member.getMemberDownload(jwt).then((blob) => {
					// Create blob link to download
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.setAttribute('download', `Members.xlsx`);

					// Append to html page
					document.body.appendChild(link);

					// Force download
					link.click();

					// Clean up and remove the link
					link.parentNode!.removeChild(link);
				});
			})
			.then(() => {
				setLoading(false);
			})
			.then(() => {
				props.history.push(match.path);
			});
	}, []);

	return <>{loading === true ? <FullPageLoading /> : <></>}</>;
};
