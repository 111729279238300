import React, { useState, useContext } from 'react';

import { Form, Input, InputGroupText, InputGroup } from 'reactstrap';

import { member } from '../../../api';
import UserContext from '../../UserContext';

import './Search.scss';
import { FaSearch, FaSpinner } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router-dom';
import { MemberDB } from 'api/members';

interface Props extends RouteComponentProps {}

export const Search = (props: Props) => {
	const { jwt } = useContext(UserContext);
	const { history } = props;

	const [isSearching, setIsSearching] = useState(false);
	const [userInput, setUserInput] = useState('');
	const [displaySearchResultsDropdown, setDisplaySearchResultsDropdown] = useState(false);
	const [searchResults, setSearchResults] = useState<Array<MemberDB>>([]);

	function onChange(e: React.ChangeEvent<HTMLInputElement>) {
		setUserInput(e.currentTarget.value);
	}

	function onFocus(e: React.FocusEvent<HTMLInputElement, Element>) {
		setDisplaySearchResultsDropdown(true);
	}

	function onBlur(e: React.FocusEvent<HTMLInputElement, Element>) {
		setTimeout(() => {
			setDisplaySearchResultsDropdown(false);
		}, 1200);
	}

	function handleSearchResultClick(docID: string) {
		history.push(`/administrator/member/${docID}`);
		setDisplaySearchResultsDropdown(false);
	}

	/**
	 *
	 * @param {*} e
	 */
	async function handleSearchFormAction(e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) {
		e.preventDefault();

		if (!isSearching) {
			if (userInput.length > 0) {
				setIsSearching(true);

				try {
					const results = await member.search(jwt, userInput);

					setSearchResults(results);
					setDisplaySearchResultsDropdown(true);
					setIsSearching(false);
				} catch (error) {
					setIsSearching(false);
				}
			}
		}
	}

	return (
		<div className="top-nav-bar-search">
			<Form onSubmit={(e) => handleSearchFormAction(e)}>
				<InputGroup className="no-border">
					<Input
						type="text"
						placeholder="Search Members..."
						data-lpignore="true"
						onChange={(e) => onChange(e)}
						onFocus={(e) => onFocus(e)}
						onBlur={(e) => onBlur(e)}
						disabled={isSearching}
						value={userInput}
					/>
					<InputGroupText onClick={(e) => handleSearchFormAction(e)}>
						{!isSearching ? <FaSearch /> : <FaSpinner size={12} className="fa-spin" />}
					</InputGroupText>
				</InputGroup>
				<ul className={`suggestions ${displaySearchResultsDropdown === true && searchResults != null && searchResults.length > 0 ? 'show' : ''}`}>
					{searchResults != null ? (
						searchResults.map((suggestion, index) => {
							return (
								<li
									key={`admin-navbar-search-result-${suggestion.docID}`}
									onClick={() => {
										handleSearchResultClick(suggestion.docID);
									}}
								>
									{suggestion['last-name']}, {suggestion['first-name']}
								</li>
							);
						})
					) : (
						<></>
					)}
				</ul>
			</Form>
		</div>
	);
};
