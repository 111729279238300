import React, { useState, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Container, Col, Row } from 'reactstrap';

import { AuthUser, login } from '../../api';
import UserContext from '../UserContext';

import { LoginForm } from './login-helpers/Login';
import { VerifyForm } from './login-helpers/Verify';

interface Props extends RouteComponentProps {}

export const Login = (props: Props) => {
	const { history } = props;
	const { setUserCredentials } = useContext(UserContext);

	const [loginToken, setLoginToken] = useState<string | null>(null);
	const [state, setState] = useState('login');

	function handlePageRedirect() {
		// Default is the dashboard
		let redirectUrl = '/administrator/dashboard';

		if (history.location != null) {
			const search = new URLSearchParams(history.location.search);
			const base64encodedUrl = search.get('l');

			if (base64encodedUrl != null) {
				let base64Buffer = Buffer.from(base64encodedUrl, 'base64');
				redirectUrl = base64Buffer.toString('ascii');
			}
		}

		history.push(redirectUrl);
	}

	/**
	 * Handles processing the success login
	 */
	async function handleLoginFormSuccess(loginToken: string) {
		setLoginToken(loginToken);
		setState('verify');
	}

	/**
	 * Handles processing the success login
	 */
	async function handleVerifyFormSuccess(loginData: { access: AuthUser; refresh: string }) {
		// We are logged in
		await setUserCredentials(loginData);

		// This was successful so lets redirect to the url
		handlePageRedirect();
	}

	return (
		<div className="full-page section-image">
			<div className="login-page">
				<Container>
					<Row className="justify-content-center align-items-center">
						<Col lg="4" md="6">
							{state === 'login' ? (
								<LoginForm onSubmit={handleLoginFormSuccess} />
							) : state === 'verify' ? (
								<VerifyForm loginToken={loginToken} onSubmit={handleVerifyFormSuccess} />
							) : (
								<></>
							)}
						</Col>
					</Row>
				</Container>
				<div
					className="full-page-background"
					style={{
						backgroundImage: `url(${process.env.REACT_APP_FRONTEND_BACKGROUND_IMAGE || '/img/bg/default-background.jpg'})`,
					}}
				/>
			</div>
		</div>
	);
};
