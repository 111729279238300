import React, { useState, useContext, useEffect } from 'react';
import { Switch, Route, NavLink as RouterNavLink, Redirect, useRouteMatch } from 'react-router-dom';

import { Card, CardHeader, CardBody, CardTitle, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, FormGroup, Button } from 'reactstrap';
import toast from 'react-hot-toast';

import { SystemPreferencesSection } from './partials/general-settings/SystemPreferencesSection';
import { DataFieldSection } from './partials/general-settings/DataFieldSection';
import { CustomizationsSection } from './partials/general-settings/CustomizationsSection';

import { setting } from '../../api';
import UserContext from '../UserContext';
import { FaSpinner } from 'react-icons/fa';
import { SettingsGeneralDB } from 'api/settings/general';

interface Props {}

export const GeneralSettings = (props: Props) => {
	const { jwt } = useContext(UserContext);
	const { path, url } = useRouteMatch();
	const [data, setData] = useState<SettingsGeneralDB>({ data_fields: [], site_name: '' });
	const [isLoaded, setIsLoaded] = useState(false);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);
	const [hasFormChanged, setHasFormChanged] = useState(false);

	/**
	 * This function will retrieve the settings data from the backend
	 */
	useEffect(() => {
		setting.general
			.get(jwt)
			.then((settings) => {
				setData(settings);
				setIsLoaded(true);
			})
			.catch((e) => {
				// TODO: Handle better
				console.log('error');
				console.log(e);
			});
	}, []);

	/**
	 * This function handles changing any of the fields
	 * @param fieldName the name of the field to be updated
	 * @param value the new value to be saved
	 */
	const handleFieldChange = (fieldName: keyof SettingsGeneralDB, value: any) => {
		const newData = { ...data };
		newData[fieldName] = value;

		setData(newData);
		setHasFormChanged(true);
	};

	/**
	 * This function will save settings to the backend
	 */
	const handleSettingsSave = () => {
		Promise.resolve()
			.then(() => {
				setFormSaveInProgress(true);
			})
			.then(() => {
				return setting.general.update(jwt, data).then((result) => {
					setHasFormChanged(false);
					setFormSaveInProgress(false);
					toast.success('Successfully saved settings');
				});
			});
	};

	/**
	 * The render function for the React component
	 */
	return (
		<div className="content general-settings">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4">General Settings</CardTitle>
						</CardHeader>
						<CardBody>
							{isLoaded ? (
								<>
									<div className="nav-tabs-navigation">
										<div className="nav-tabs-wrapper">
											<Nav id="tabs" role="tablist" tabs>
												<NavItem key={`system-preference-nav-item`}>
													<NavLink tag={RouterNavLink} to={`${url}/system-preference`} exact>
														System Preferences
													</NavLink>
												</NavItem>
												<NavItem key={`data-field-nav-item`}>
													<NavLink tag={RouterNavLink} to={`${url}/data-field`} exact>
														Data Fields
													</NavLink>
												</NavItem>
												<NavItem key={`customizations-nav-item`}>
													<NavLink tag={RouterNavLink} to={`${url}/customizations`} exact>
														Customizations
													</NavLink>
												</NavItem>
											</Nav>
										</div>
									</div>

									<TabContent>
										<TabPane>
											<Switch>
												<Route exact path={path}>
													<Redirect push to={`/administrator/setting/general/system-preference`} />
												</Route>
												<Route exact path={`${path}/system-preference`}>
													<SystemPreferencesSection
														key={`general-settings-system-preferences-tab`}
														data={data}
														onChange={handleFieldChange}
													/>
												</Route>
												<Route exact path={`${path}/data-field/:action?`}>
													<DataFieldSection
														key={`general-settings-data-field-tab`}
														data={data}
														onChange={(newData) => handleFieldChange('data_fields', newData)}
													/>
												</Route>
												<Route exact path={`${path}/customizations`}>
													<CustomizationsSection
														key={`general-settings-customizations-tab`}
														data={data}
														onChange={handleFieldChange}
													/>
												</Route>
											</Switch>
										</TabPane>
									</TabContent>
									<hr />
									<FormGroup check row>
										<Col sm={{ size: 10, offset: 2 }}>
											<Button color="primary" onClick={() => handleSettingsSave()} disabled={!hasFormChanged || formSaveInProgress}>
												{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Save'}
											</Button>
										</Col>
									</FormGroup>
								</>
							) : (
								<FaSpinner size={12} color="#ccc" className="fa-spin" />
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
